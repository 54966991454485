export let invoiceChaseStates: StateTypes[] = [
    {id: 1, name: "Start", key: "start"},
    {id: 2, name: "Stop", key: "stop"},
    {id: 3, name: "Pending", key: "pending"}
]
export let invoiceChaseFrequencies: StateTypes[] = [
    {id: 1, name: "Weekly", key: "weekly"},
    {id: 2, name: "Montly", key: "monthly"}
]


export class StateTypes {
    id!: number
    name!: string
    key!: string
    data?: string
}