<button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-primary"
                  popoverTitle="Popover Show Event"
                  ngbPopover="Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie."
                  #CloseEventAfter="ngbPopover"
                  triggers="click:mouseleave"
                  (click)="closeEvent()"
                >
                  Get Started
                </button>