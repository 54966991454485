import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Dispute } from 'app/main/dispute/dispute.data';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'dispute-list',
  templateUrl: './dispute-list.component.html',
  styleUrls: ['./dispute-list.component.scss']
})
export class DisputeListComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public contentHeader: object;
  @Input() records: Dispute[] = new Array<Dispute>()

  Monthly = false;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {PricingService} _pricingService
   */
  constructor() {
    this._unsubscribeAll = new Subject();
  }


  ngOnInit(): void {
    
  }

  getAmount(data: Dispute){
    return data.invoice.total_amount
  }
  
  getClient(data: Dispute){
      return data.invoice.client.name
  }
  
  getInvoice(data: Dispute){
      return data.invoice.doc_number
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
