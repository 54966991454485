import { OAuth } from 'app/services/auth/user.model';
import { Role } from './role';

export class User extends OAuth{
  password?: string;
  firstName?: string;
  lastName?: string;
  role: Role;
  name: string 
}
