import { Invoice } from "app/services/invoices/invoice.model"

export class Collection {

    id!: number
    uuid!: string
    invoice_id!: number
    company_id!: number
    user_id!: string
    collection_date!: string
    collector_id!: string
    amount!: number
    comments!: string
    _state!: string
    status!: string
    created_at!: string
    updated_at!: string
    invoice!: Invoice
}

export class CollectionActions {

    id!: string
    uuid!: string
    company_id!: string
    collection_id!: string
    action_date!: string
    action!: string
    notes!: string
    _state!: string
    status!: string
    created_at!: string
    updated_at!: string
}

export class Collector {

    id!: string
    uuid!: string
    name!: string
    address!: string
    country!: string
    postcode!: string
    latlon!: string
    city!: string
    phone!: string
    email!: string
    _state!: string
    _type!: string
    status!: string
    created_at!: string
    updated_at!: string
}