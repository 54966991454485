<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 *ngIf="type=='create'" class="modal-title" id="exampleModalCenterTitle">Add {{ action }} Template</h5>
    <h5 *ngIf="type=='update'" class="modal-title" id="exampleModalCenterTitle">Update {{ action }} Template</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    

    <div class="row">

      <div class="col-lg-4">

        <h4>Custom Values</h4>
        <div class="custom-values-holder">

          <span 
          *ngFor="let item of placeholders" 
          (click)="insertTextAtCursor(item.key)"
          class="badge badge-default-light badge-default">{{ item.name }} 
          <i class="fa fa-plus-circle"></i></span>

        </div>

      </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-5">
                  <app-input
                      [type]="'text'"
                      [label]="'Identifiable Name'"
                      [class]="'form-control'"
                      [id]="'name'"
                      [value]="payload.name"
                      [placeholder]="'Identifiable Name'"
                      [(data)]="payload.name">
                  </app-input>
            </div>
            <div class="col-lg-7">
              <app-input
                  [type]="'text'"
                  [label]="'Subject'"
                  [class]="'form-control'"
                  [id]="'subject'"
                  [value]="payload.subject"
                  [placeholder]="'Subject'"
                  [(data)]="payload.subject">
              </app-input>
            </div>
            <div class="col-lg-12">

              <label>Template</label>
              <div id="editor-container"></div>

              <!-- <div class="editor-container" style="height: 400px;"></div> (ngModelChange)="dataChange.emit(data)" -->

              <!-- <quill-editor
                #editor 
                (onEditorCreated)="onEditorCreated($event)"
                name="addDescription"
                customToolbarPosition="'bottom'"
                [(ngModel)]="payload.body"
                id="editor-container"
                class="border-bottom-0 editor-container"
                placeholder="Write something...">
                <div quill-editor-toolbar>
                  <div class="d-flex justify-content-end desc-toolbar border-top-0">
                    <span class="ql-formats mr-0">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-align"></button>
                      <button class="ql-link"></button>
                    </span>
                  </div>
                </div>
              </quill-editor> -->
              <!-- end quild -->
    
            </div>
        </div>
        </div>
        
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>


<button *ngIf="type=='update'" class="btn btn-sm btn-primary" (click)="open(modalVC)" rippleEffect>
  <i class="fa fa-edit"></i> Edit
</button>

<button *ngIf="type=='create'" class="btn btn-sm btn-default" (click)="open(modalVC)" rippleEffect>
  <i class="fa fa-plus-circle"></i>  Add
</button>
