<label *ngIf="label!=''">

    {{ label }}
  
    <span class="text-primary" *ngIf="type==='date' || type==='datetime-local'">: {{ value }}</span>
  
  </label>
  <input
      [type]="type"
      [class]="class"
      [disabled]="disabled"
      autocomplete="off"
      [(ngModel)]="data"
      (ngModelChange)="dataChange.emit(data)"
      [id]="id"
      [placeholder]="placeholder"
      [value]="value">
  <small>{{ info }}</small>
  