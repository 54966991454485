import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as snippet from 'app/main/components/popovers/popovers.snippetcode';
import { ClientService } from 'app/services/clients/client.service';
import { FinanceEligibilityService } from 'app/services/finance/finance.service';
import { Client } from 'app/services/invoices/invoice.model';

@Component({
  selector: 'finance-eligible',
  templateUrl: './finance-eligible.component.html',
  styleUrls:['./finance-eligible.component.scss']
})
export class FinanceEligibleNoticeComponent implements OnInit {
  
    @ViewChild('modalSuccess', { static: true }) modalSuccess: any;
  
    public customers: Client[] = new Array<Client>();
  
    constructor(
      private modalService: NgbModal,
      private financeEligibilityService: FinanceEligibilityService
    ) {


    }
  
    /**
    * On init
    */
    ngOnInit(): void {
        this.findEligibleCustomers();
    }

    findEligibleCustomers(){
        this.financeEligibilityService.find({target: "eligibility"}).subscribe(response => {
          this.customers = this.financeEligibilityService.response(response, false)
          if(this.customers && this.customers.length > 0){
              this.modalOpenSuccess(this.modalSuccess);
          }
        })
    }

    // modal Open Success
    modalOpenSuccess(modalSuccess) {
      this.modalService.open(modalSuccess, {
        centered: true,
        windowClass: 'modal modal-success'
      });
    }
}
