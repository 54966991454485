import { UtilService } from './../../../../services/utils.service';
import { Invoice } from './../../../../services/invoices/invoice.model';
import { InvoiceAccessor } from './../../../../services/invoices/invoice.accessor';
import { InvoiceService, ScheduleService } from './../../../../services/invoices/invoice.service';
import { Component, OnInit, OnDestroy,Output, EventEmitter, Input } from '@angular/core';
import * as snippet from 'app/main/components/tabs/tabs.snippetcode';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Financer } from 'app/main/finance/finance.data';
import { FinancierService } from 'app/services/finance/finance.service';
import { Collector } from 'app/main/collections/collection.data.';
import { CollectorService } from 'app/services/collections/collection.service';
import { Template } from 'app/main/reminders/reminder.data';
import { Schedule } from 'app/main/receivables/receivable.data';
import { TemplateService } from 'app/services/reminders/reminders.service';


@Component({
  selector: 'clients-invoices',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss']
})
export class ClientInvoiceTableComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public isAdmin: boolean = true;
  @Input() invoices: Invoice[] = new Array<Invoice>();
  

  @Output() onSuccessResceived = new EventEmitter<any>()
  
  constructor(
    private scheduleService: ScheduleService,
    private collectorsService: CollectorService,
    private invoiceAccessor: InvoiceAccessor,
    private utilsService: UtilService,
    private templateService: TemplateService,
    private financierService: FinancierService
    ){}

  ngOnInit(): void {
      
      // this.load()//load invoices
      this.loadFinanciers()
      this.loadCollectors()
      this.loadShedule()
      this.loadTemplate()
  }

  load(){
      this.onSuccessResceived.emit(true)
  }

  public financiers: Financer[] = new Array<Financer>()

  loadFinanciers(){

    this.financierService.all().subscribe(response => {
      if(response.status == "success"){
          if(response.data){
            this.financiers = response.data
          }
      }
      // this.toastr.error(response.message)
  })
}

  public collectors: Collector[] = new Array<Collector>()
  
  loadCollectors(){

    this.collectorsService.all().subscribe(response => {
      if(response.status == "success"){
          if(response.data){
            this.collectors = response.data
          }
      }
      // this.toastr.error(response.message)
  })
}


  /**
   * Thks should sum the value in the specified column 
   * @param data 
   * @param column 
   * @returns 
   */
  sum(data: Array<any>, column: string){

    return this.utilsService.sumJsonData(data, column)
  }

  overdueDays(date: string ){

    return this.utilsService.getDateXDaysAgo(date)
  }

  public templates:Template[] = new Array<Template>()
  public schedules: Schedule[] = new Array<Schedule>()
  
  loadShedule(){
    this.scheduleService.all().subscribe(response => {
        this.schedules = this.scheduleService.response(response, false)
    })
}
loadTemplate(){
  this.templateService.all().subscribe(response => {
    this.templates = this.templateService.response(response, false)
    console.log("Logger:::", this.templates);
})
}

  getChaseState(stage: string, type: string){

      if(type == 'finance'){
        switch(stage){
          case "Financing":  return true;
          case "Financed":  return true;
          default: return false
        }
        
      }else{
        switch(stage){
          case "Chasing": return true;
          case "NoChasing": return true;
          case "Settled": return true;
          case "Dispute": return true;
          default: return false
        }
      }
  }

  ngOnDestroy(): void {
      
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}