import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Dispute } from 'app/main/dispute/dispute.data';
import { DisputeService } from 'app/services/disputes/dispute.service';
import { FinanceService } from 'app/services/finance/finance.service';
import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'financer-list',
  templateUrl: './financer-list.component.html',
  styleUrls: ['./financer-list.component.scss']
})
export class FinancerListComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public contentHeader: object;
  @Input() role: string = ""
  @Input() records: Dispute[] = new Array<Dispute>()

  Monthly = false;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {PricingService} _pricingService
   */
  constructor(
    private financeService: FinanceService,
    private toast: ToastrService
    ) {
    this._unsubscribeAll = new Subject();
  }


  ngOnInit(): void {
    
  }

  getAmount(data: Dispute){
    return data.invoice.total_amount
  }
  
  getClient(data: Dispute){
      return data.invoice.client.name
  }
  
  getInvoice(data: Dispute){
      return data.invoice.doc_number
  }

//   loadDisputes(){
//       this.disputeService.all().subscribe(response => {
//           this.records = this.disputeService.response(response, false)
//       })
//   }

  

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
