<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 *ngIf="type=='create'" class="modal-title" id="exampleModalCenterTitle">Add Template</h5>
    <h5 *ngIf="type=='update'" class="modal-title" id="exampleModalCenterTitle">Update Template</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    

    <div class="row">

      <div class="col-lg-12">
        
        <app-select
          [list]="collectors"
          [type]="'text'"
          [label]="'Collectors'"
          [id]="'collector_id'"
          [label]="'Collectors'"
          [value]="selectedCollector.name"
          [(data)]="selectedCollector">
        </app-select>
      </div>

        <div class="col-lg-6">
              <app-input
                  [type]="'number'"
                  [label]="'Amount'"
                  [class]="'form-control'"
                  [id]="'amount_collected'"
                  [value]="payload.amount"
                  [placeholder]="'Amount'"
                  [(data)]="payload.amount">
              </app-input>
        </div>

        <div class="col-lg-6">
          <app-input
              [type]="'datetime-local'"
              [label]="'Date'"
              [class]="'form-control'"
              [id]="'collection_date'"
              [value]="payload.collection_date"
              [placeholder]="'Date'"
              [(data)]="payload.collection_date">
          </app-input>
        </div>

        <div class="col-lg-12">

          <app-textarea
              [type]="'text'"
              [label]="'Description'"
              [class]="'form-control'"
              [id]="'comments'"
              [value]="payload.comments"
              [placeholder]="'Write Something...'"
              [(data)]="payload.comments">
          </app-textarea>
          <!-- end quild -->

        </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>

<a (click)="open(modalVC)" href="javascript:void(0)" ngbDropdownItem>Debt Collection</a>