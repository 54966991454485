import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `receivables/fetch/invoices`;
        this.ALL_PATH       = `receivables/list/invoices`;
        this.FIND_PATH      = `receivables/find/invoices`;
        this.UPDATE_PATH    = `receivables/update/invoices`;
        this.CREATE_PATH    = `receivables/create/invoices`;
        this.DELETE_PATH    = `receivables/delete/invoices`;
    }

    sync(params: any = {}){
        return this.appService.post(`company/synchronize`, params)
    }

    getReceivaleMetrics(){
        return this.appService.post("receivables/stats/types", {})
    }

}

@Injectable({
    providedIn: 'root'
  })
  export class InvoiceCustomerService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `receivables/fetch/customers`;
          this.ALL_PATH       = `receivables/list/customers`;
          this.FIND_PATH      = `receivables/find/customers`;
          this.UPDATE_PATH    = `receivables/update/customers`;
          this.CREATE_PATH    = `receivables/create/customers`;
          this.DELETE_PATH    = `receivables/delete/customers`;
      }

      findCompany(params){
        return this.appService.post('receivables/find/customers-report', params);
      }
  
  }

@Injectable({
    providedIn: 'root'
  })
  export class ScheduleService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `receivables/fetch/schedule`;
          this.ALL_PATH       = `receivables/list/schedule`;
          this.FIND_PATH      = `receivables/find/schedule`;
          this.UPDATE_PATH    = `receivables/update/schedule`;
          this.CREATE_PATH    = `receivables/create/schedule`;
          this.DELETE_PATH    = `receivables/delete/schedule`;
      }
  
  }

@Injectable({
    providedIn: 'root'
  })
  export class InvoiceChaseService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `receivables/fetch/chase`;
          this.ALL_PATH       = `receivables/list/chase`;
          this.FIND_PATH      = `receivables/find/chase`;
          this.UPDATE_PATH    = `receivables/update/chase`;
          this.CREATE_PATH    = `receivables/create/chase`;
          this.DELETE_PATH    = `receivables/delete/chase`;
      }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceNoteService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `receivables/fetch/notes`;
          this.ALL_PATH       = `receivables/list/notes`;
          this.FIND_PATH      = `receivables/find/notes`;
          this.UPDATE_PATH    = `receivables/update/notes`;
          this.CREATE_PATH    = `receivables/create/notes`;
          this.DELETE_PATH    = `receivables/delete/notes`;
      }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class BillableService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `receivables/fetch/billable`;
          this.ALL_PATH       = `receivables/list/billable`;
          this.FIND_PATH      = `receivables/find/billable`;
          this.UPDATE_PATH    = `receivables/update/billable`;
          this.CREATE_PATH    = `receivables/create/billable`;
          this.DELETE_PATH    = `receivables/delete/billable`;
      }
  }