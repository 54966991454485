<div class="modal-success d-inline-block">
    <!-- Button trigger modal -->
    <button style="display: none;" type="button"
      class="btn btn-outline-success"
      (click)="modalOpenSuccess(modalSuccess)"
      rippleEffect>Eligibility</button>

    <!-- Modal -->
    <ng-template #modalSuccess let-modal>
        <!-- <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel160">Eligible Customers Found </h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div> -->
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="wrap-eligibility">

                <h4>
                    {{ customers.length || 6 }} Finance Eligible Customers
                </h4>
                <p>
                    Hey! There are <strong>{{ customers.length || 6 }} customers</strong> with excellent credit ratings,
                    and you might want to consider financing their invoices with just a click. 
                </p>
                <p>
                    These customers are eligible for invoice financing, presenting a great opportunity to 
                    enhance cash flow and efficiently manage working capital.
                </p>
                <a class="btn btn-success" routerLink="/finances/eligible-invoices" (click)="modal.close('Accept click')" rippleEffect>
                    View Customers
                </a>

            </div>
        </div>
        <!-- <div class="modal-footer">
           
        </div> -->
        </ng-template>
    <!-- / Modal -->
  </div>