import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { Financer, Financing } from 'app/main/finance/finance.data';
import { FinanceService, FinancierService } from 'app/services/finance/finance.service';
import { Invoice } from 'app/services/invoices/invoice.model';
import { agreement } from 'app/services/constants/constants';

@Component({
  selector: 'invoice-finance',
  templateUrl: './invoice-finance.component.html',
  styleUrls: ['./invoice-finance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceFinanceComponent implements OnInit {

  // public
  @Input() type: string = ""
  @Input() payload: Financing = new Financing()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private financeService: FinanceService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  ngAfterViewInit(): void {
    
    this.getInvoiceAmount()
  }

  public agreement: string = agreement

  getInvoiceAmount(){
      try{

        this.payload.amount = this.invoice.total_amount;
        console.log("checkinvoid", this.invoice)

      }catch(e){ return 0; }
  }

  @Input() financiers: Financer[] = new Array<Financer>()
  @Input() invoice:Invoice = new Invoice()
  public selectedFinancer: Financer = new Financer()

  public running: string = ""
  updateOrCreate(){

    let request: any
    this.running = "btn-loading"

    console.log("Selected::: ", this.selectedFinancer)
    if(!this.selectedFinancer.id){
        this.toastr.error("Please, select financier"); return;
    }

    if(!this.invoice.client){
      this.toastr.error("This invoice is missing valid client"); return;
    }

    this.payload.client_id = this.invoice.client.id
    this.payload.invoice_id = this.invoice.id
    this.payload.financer_id = this.selectedFinancer.id

      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.financeService.update(body)
      }else{
          //this is create
          request = this.financeService.create(this.payload)
      }

      request.subscribe(response => {

        this.running = ""

        // console.log("response::: ", response, response.status)
        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)

    }, error => {
        this.toastr.error("Request failed to complete")
    })
  }

  public description: string = ""
  open(modalVC: any) {

    this.modalService.open(modalVC, {
      centered: true
    });
  }
}
