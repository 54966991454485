import { CompanyService } from 'app/services/company/company.service';
import { Company, Sync } from 'app/main/account/connect/connect.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyAccessor {

    constructor( private companyService: CompanyService ){}

    // Private
    private _company: BehaviorSubject<Company[] | null> = new BehaviorSubject(null);
    

     /** * Getter for tags             */
     get company$(): Observable<Company[]>
     {
         return this._company.asObservable();
     }

     getCompany(){
        this.companyService.all().subscribe(response => {
            if(response.status=="success"){ this._company.next(response.data) }
        }, error => "")
     }

    
}
