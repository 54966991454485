import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import * as snippet from 'app/main/components/popovers/popovers.snippetcode';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls:['./notice.component.scss']
})
export class NoticeModalComponent implements OnInit {
  // public

  @ViewChild('OpenEvent') OpenEvent;
  @ViewChild('CloseEvent') CloseEvent;

        constructor(
          private modalService: NgbModal,
          private authenticationService: AuthenticationService) {}

        /**
        * On init
        */
       public currentUser: User = new User()
        ngOnInit(): void {
        
            this.currentUser = this.authenticationService.getActiveUser()
        }

        openEvent() {
            this.OpenEvent.open();
        }
        
        closeEvent() {
            this.CloseEvent.close();
        }
         // modal Open Success
        modalOpen(modalSuccess) {
          this.modalService.open(modalSuccess, {
            centered: true,
            windowClass: 'modal modal-primary'
          });
        }
}
