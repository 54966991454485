<div class="row match-height">
    <!-- Company Table -->
    <div class="col-lg-12 col-12 col-no-padding">
      <div class="card card-company-table">
        <div class="card-body p-0">
          <div class="table-responsive" style="min-height: 400px;">
            <table class="table">
              <thead>
               
                <tr>
                  <th>Invoices</th>
                  <th>Clients</th>
                  <th>Due Date</th>
                  <th>Total</th>
                  <th class="text-nowrap">OverDue Days</th>
                  <!-- <th>Status</th> -->
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                
                <tr *ngFor="let data of invoices">
                  <td class="text-nowrap">
                    <div class="d-flex flex-column">#{{ data.doc_number }}</div>
                  </td>
                 
                  <td class="text-nowrap">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">
                          <a routerLink="/clients/view/{{ data.client.uuid }}" href="javascript:;">{{ data.client.name }}</a>
                        </div>
                        <div class="font-small-2 text-muted" *ngIf="data.company">{{ data.company.name }}</div>
                      </div>
                    </div>
                  </td>

                  <td class="text-nowrap">
                    <div class="d-flex flex-column">{{ data.due_date }} </div>
                  </td>
                  <td>£{{ data.total_amount }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                        <span class="text-danger"> {{ overdueDays(data.due_date) }}</span>
                    </div>
                  </td>
                  <!-- <td>{{ data._chase || '-' }}</td> -->
                  <td class="text-nowrap clear-styles">
                    <table class="no-padding no-margin pull-right mr-10">
                          <tr>
                            <td>
                              <invoice-chase *ngIf="data._chase!='Settled'" (onSuccessResceived)="load($event)" [schedules]="schedules" [invoice]="data"></invoice-chase>
                            </td>
                            <td>
                                <invoice-finance *ngIf="data._finance=='None'" [invoice]="data" (onSuccessResceived)="load($event)" [financiers]="financiers"></invoice-finance>
                                <button *ngIf="data._finance!='None'" class="btn btn-sm btn-default">{{ data._finance }}</button>
                            </td>
                            <td>
                              <!-- Start of dropdown -->
                              <div ngbDropdown>
                                <a ngbDropdownToggle
                                  href="javascript:void(0);"
                                  class="hide-arrow"
                                  id="dropdownTransactions"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false">
                                  <i data-feather="more-vertical" class="text-body cursor-pointer"></i>
                                </a>
                                <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                                  <a href="javascript:void(0)" routerLink="/receivable/preview/{{data.uuid}}" ngbDropdownItem>View Invoice</a>
                                  <invoice-chase [type]="'link'" *ngIf="data._chase!='Settled'" [schedules]="schedules" (onSuccessResceived)="load($event)" [invoice]="data"></invoice-chase>
                                  <invoice-note [invoice]="data"></invoice-note>
                                  <invoice-dispute [invoice]="data"></invoice-dispute>
                                  <invoice-collect [invoice]="data" [collectors]="collectors"></invoice-collect>
                                </div>
                              </div>
                              <!-- End of Dropdown -->
                            </td>
                          </tr>
                          
                      </table>
                  </td>
                </tr>

               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Company Table -->

    <!-- Transactions Card -->
  </div>