<div class="row match-height">
    <!-- Company Table -->
    <div class="col-lg-12 col-12 col-no-padding">
      <div class="card card-company-table">
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Clients</th>
                  <th>Invoices</th>
                  <th>Value</th>
                  <th>Received</th>
                  <th>Outstanding</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-nowrap">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bolder mb-25">#345665</span>
                    </div>
                  </td>
                 
                  <td>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">Johnson  Limited</div>
                        <div class="font-small-2 text-muted">info@johnsonltd.net</div>
                      </div>
                    </div>
                  </td>
                  
                  <td class="text-nowrap">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bolder mb-25">35</span>
                    </div>
                  </td>
                  <td>£22,901.02</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bolder mr-1 text-success">£11,832.30</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bolder mr-1 text-danger">£11,232.30</span>
                    </div>
                  </td>
                  <td class="text-nowrap clear-styles">
                    <table class="no-padding no-margin pull-right mr-10">
                          <tr>
                            
                            <td>
                              <button type="button" class="btn btn-primary btn-sm  mr-1" rippleEffect>View</button>
                            </td>
                            <td>
                              <!-- Start of dropdown -->
                              <div ngbDropdown>
                                <a ngbDropdownToggle
                                  href="javascript:void(0);"
                                  class="hide-arrow"
                                  id="dropdownTransactions"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false">
                                  <i data-feather="more-vertical" class="text-body cursor-pointer"></i>
                                </a>
                                <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                                  <a href="javascript:void(0)" ngbDropdownItem>Add Note</a>
                                  <a href="javascript:void(0)" ngbDropdownItem>Connection</a>
                                  <a href="javascript:void(0)" ngbDropdownItem>Finances</a><!-- Show all finance requests -->
                                  <a href="javascript:void(0)" ngbDropdownItem>Open Dispute</a>
                                </div>
                              </div>
                              <!-- End of Dropdown -->
                            </td>
                          </tr>
                          
                      </table>
                  </td>
                </tr>

               

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Company Table -->

    <!-- Transactions Card -->
  </div>