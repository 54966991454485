import { AccessLevelControlResolver } from './services/auth/acl.resolver';
import { MiscellaneousModule } from './main/pages/miscellaneous/miscellaneous.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { ClientsModule } from './main/clients/clients.module';

const appRoutes: Routes = [
  { path: '', redirectTo:'/home', pathMatch: 'full'},
  {
    path: '',
    loadChildren: () => import('./main/website/website.module').then(m => m.WebsiteModule)
  },
  {
    path: '',
    loadChildren: () => import('./main/account/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'receivable',
    loadChildren: () => import('./main/receivables/receivable.module').then(m => m.ReceivablesModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./main/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'analysis',
    loadChildren: () => import('./main/analysis/analysis.module').then(m => m.AnalysisModule)
  },
  {
    path: 'reminders',
    loadChildren: () => import('./main/reminders/reminders.module').then(m => m.RemindersModule)
  },
  {
    path: 'credit',
    loadChildren: () => import('./main/credit-checks/credit-checks.module').then(m => m.CreditChecksModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./main/clients/clients.module').then(m => m.ClientsModule)
  },
  {
    path: 'collections',
    loadChildren: () => import('./main/collections/collection.module').then(m => m.CollectionModule)
  },
  {
    path: 'finances',
    loadChildren: () => import('./main/finance/finance.module').then(m => m.FinanceModule)
  },
  {
    path: 'disputes',
    loadChildren: () => import('./main/dispute/disputes.module').then(m => m.DisputeModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./main/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'extensions',
  //   loadChildren: () => import('./main/extensions/extensions.module').then(m => m.ExtensionsModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'forms',
  //   loadChildren: () => import('./main/forms/forms.module').then(m => m.FormsModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'tables',
  //   loadChildren: () => import('./main/tables/tables.module').then(m => m.TablesModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'charts-and-maps',
  //   loadChildren: () => import('./main/charts-and-maps/charts-and-maps.module').then(m => m.ChartsAndMapsModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/page-not-found' //Error 404 - Page not found
  }
];

@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        MiscellaneousModule
    ],
    providers: [
        HttpClientModule,
        AuthGuard,
        AccessLevelControlResolver,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        // ! IMPORTANT: Provider used to create fake backend, comment while using real API
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
