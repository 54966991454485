import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `receivables/fetch/customers`;
        this.ALL_PATH       = `receivables/list/customers`;
        this.FIND_PATH      = `receivables/find/customers`;
        this.UPDATE_PATH    = `receivables/update/customers`;
        this.CREATE_PATH    = `receivables/create/customers`;
        this.DELETE_PATH    = `receivables/delete/customers`;
    }

}



