import { Invoice } from "app/services/invoices/invoice.model"

export class Financer {

    id!: number
    uuid!: string
    name!: string
    address!: string
    country!: string
    postcode!: string
    city!: string
    phone!: string
    email!: string
    _state!: string
    _type!: string
    status!: number
    created_at!: string
    updated_at!: string
}

export class Financing {

    id!: number
    uuid!: string
    financer_id!: number
    company_id!: number
    invoice_id!: number
    client_id!: number
    amount!: number
    start_date!: string
    end_date!: string
    approved_by_id!: number
    _state!: number
    status!: number
    created_at!: string
    updated_at!: string
    invoice!: Invoice
}

export class FinancePayment {

    id!: number
    uuid!: string
    company_id!: number
    financing_id!: number
    financer_id!: number
    invoice_id!: number
    client_id!: number
    amount!: number
    payment_date!: string
    _state!: number
    status!: number
    created_at!: string
    updated_at!: string
}