import { state } from '@angular/animations';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { StateTypes } from './chase.data';
import { CustomTemplateService } from 'app/services/reminders/reminders.service';
import { ValueProcessor } from 'app/services/finance/processor.data';
import { valueProcessorData } from 'app/services/finance/data.service';

@Component({
  selector: 'template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplatePreviewComponent implements OnInit {

  // public
  @Input() payload: Template = new Template()
  @Input() type: string = ""
  @Input() action: string = ""
  @Output() onSuccessResceived = new EventEmitter<any>()
  @Input() templates:Template[] = new Array<Template>()
  public selectedTemplate: Template = new Template()

  constructor(
    private modalService: NgbModal,
    private customTemplateService: CustomTemplateService,
    private dataProcessorService: ValueProcessor,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

    this.selectedTemplate = this.payload
  }

  private quill: any;
  private isEditorActive: boolean = false;

  private initializeQuill() {
    const editorContainer = document.getElementById('editor-container');
    if (editorContainer) {
      this.quill = new (window as any).Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            [{ 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            ['link']
          ]
        }
      });

      // Optionally, set the content of the editor
      this.quill.root.innerHTML = this.selectedTemplate.body || "";

      // Add event listeners if needed
      this.quill.on('text-change', () => {
        this.selectedTemplate.body = this.quill.root.innerHTML;
      });

      this.quill.on('editor-change', (eventName: string) => {
        if (eventName === 'selection-change') {
          this.isEditorActive = this.quill.hasFocus();
        }
      });

      this.quill.on('selection-change', (range: any) => {
        if (range) {
          this.storedSelection = range; // Store the current selection
        }
      });
    }
  }

  sortMapData(value:string){

      if(value){
        return this.dataProcessorService.map(valueProcessorData, value)
      }
      return ""
  }

  public storedSelection: any
  insertTextAtCursor(text: string): void {
   
    if (this.quill) {
      const range = this.storedSelection;
      this.quill.insertText(range.index, text, { 'background': "#d8d5f6" });
      this.quill.setSelection(range.index + text.length); // Optionally move cursor after the inserted text
    } else {
      console.error('Quill editor instance is not initialized or no selection stored.');
    }
  }

  @HostListener('window:focus', ['$event'])
  onWindowFocus(event: FocusEvent): void {
    // Update focus status on window focus event
    this.isEditorActive = this.quill && this.quill.hasFocus();
  }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: FocusEvent): void {
    // Update focus status on window blur event
    this.isEditorActive = false;
  }


  updateOrCreate(){

    this.payload.body = this.selectedTemplate.body
    this.payload._type = this.type

    let request: any
      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.customTemplateService.update(body)
      }else{
          //this is create
          request = this.customTemplateService.create(this.payload)
      }

      request.subscribe(response => {

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error("Unable to complete request")
    })
  }

  public description: string = ""
  open(modalVC: any) {

    this.modalService.open(modalVC, {
      size: "lg",
      centered: true
    });

    this.initializeQuill();
  }

  onDataChanged($event: Template){

     this.quill.root.innerHTML = $event.body
  }

  onCheckboxChange($event){

  }

  public showPreview = false

  public placeholders:StateTypes[] = //new Array<StateTypes>()
  [
    {"id": 1, "name": "Customer: Name", "key": "<customer_name>"},
    {"id": 2, "name": "Recipient: First name", "key": "<recipient_firstname>"},
    {"id": 3, "name": "Recipient: Surname", "key": "<recipient_surname>"},
    {"id": 4, "name": "Recipient: Full name", "key": "<recipient_fullname>"},
    {"id": 5, "name": "Sender: First name", "key": "<sender_firstname>"},
    {"id": 6, "name": "Sender: Surname", "key": "<sender_surname>"},
    {"id": 7, "name": "Sender: Full name", "key": "<sender_fullname>"},
    {"id": 8, "name": "Customer: Payment Portal", "key": "<customer_payment_portal>"},
    {"id": 9, "name": "Due date", "key": "<due_date>"},
    {"id": 10, "name": "Invoice: Issue date", "key": "<invoice_issue_date>"},
    {"id": 11, "name": "Invoice: Reference number", "key": "<invoice_reference_number>"},
    {"id": 12, "name": "Invoice: Reference number - with link", "key": "<invoice_reference_number_with_link>"},
    {"id": 13, "name": "Invoice: Total", "key": "<invoice_total>"},
    {"id": 14, "name": "Invoice: Amount owed", "key": "<invoice_amount_owed>"},
    {"id": 15, "name": "Days overdue", "key": "<days_overdue>"},
    {"id": 16, "name": "Weeks overdue", "key": "<weeks_overdue>"},
    {"id": 17, "name": "Months overdue", "key": "<months_overdue>"},
    {"id": 18, "name": "Days until due date", "key": "<days_until_due_date>"},
    {"id": 19, "name": "Weeks until due date", "key": "<weeks_until_due_date>"},
    {"id": 20, "name": "Months until due date", "key": "<months_until_due_date>"}
  ]
}
