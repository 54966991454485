    <label *ngIf="label!=''">{{ label }}</label>
    <ng-select
        [items]="list"
        [disabled]="disabled"
        bindLabel="{{name || '' }}"
        [(ngModel)]="data"
        (change)="updateSelected($event)"
        class="form-select-md select2 ps-0"
        [placeholder]="placeholder">
    </ng-select>
