export let valueProcessorData = {
    "id": 1,
    "uuid": "6fe8494c-ed7e-468a-ba35-bd7447be858e",
    "source_record_id": 130,
    "company_id": 7,
    "customer_id": 1,
    "doc_number": "1037",
    "currency": "USD",
    "transaction_date": "2022-08-20 00:00:00",
    "due_date": "2022-09-19 00:00:00",
    "total_amount": 362.07,
    "_finance": "Financing",
    "_chase": "Chasing",
    "_state": "None",
    "status": "1",
    "created_at": "2024-06-27T11:02:27.000000Z",
    "updated_at": "2024-09-11T09:38:49.000000Z",
    "client": {
        "id": 1,
        "uuid": "3db7dd7e-af2a-4a1f-982f-79cdc676e096",
        "source_record_id": 24,
        "company_id": 7,
        "email": "Familiystore@intuit.com",
        "name": "Sonnenschein Family Store",
        "_state": "0",
        "status": "1",
        "created_at": "2024-06-27T11:02:27.000000Z",
        "updated_at": "2024-08-09T11:43:33.000000Z"
    },
    "company": {
        "id": 7,
        "uuid": "6b7a6eb5-4304-46c9-8294-46fe5be7339b",
        "name": "Feramy Limited",
        "logo": "-",
        "address": "1 Earls Wood Drive, Coventry",
        "timezone": "-",
        "currency_id": 0,
        "_state": "Basic",
        "status": 1,
        "created_at": "2024-06-25T10:00:03.000000Z",
        "updated_at": "2024-06-27T11:00:20.000000Z"
    },
    "chaser": {
        "id": 1,
        "uuid": "26520412-fb5e-4e98-b55c-81b44b544784",
        "company_id": 7,
        "invoice_id": 1,
        "schedule_id": 1,
        "next_chase_date": "2024-09-25",
        "last_chase_date": "2024-09-11",
        "_state": "Chasing",
        "status": 1,
        "created_at": "2024-09-11T09:38:49.000000Z",
        "updated_at": "2024-09-11T15:39:02.000000Z",
        "schedule": {
            "id": 1,
            "uuid": "7a0e706f-5ef0-4d4b-98ff-549d9",
            "company_id": 7,
            "name": "Weekly Follow-up",
            "frequency": "Bi-Weekly",
            "template_id": 1,
            "start_before": 5,
            "start_offset": 4,
            "end_offset": 11,
            "_state": "Default",
            "status": 1,
            "created_at": "2024-08-30T05:45:51.000000Z",
            "updated_at": "2024-08-30T09:50:44.000000Z"
        }
    }
}