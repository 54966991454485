import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { Collection, Collector } from 'app/main/collections/collection.data.';
import { Client, CreditCheckClient, Invoice } from 'app/services/invoices/invoice.model';
import { CollectionService } from 'app/services/collections/collection.service';
import { ClientService } from 'app/services/clients/client.service';
import { Customer } from 'app/services/customers/customer.model';
import { InvoiceCustomerService } from 'app/services/invoices/invoice.service';

@Component({
  selector: 'run-check',
  templateUrl: './run-check.component.html',
  styleUrls: ['./run-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RunCheckComponent implements OnInit {

  // public
  @Input() type: string = ""
  @Input() index: string = ""
  @Input() customer: Client = new Client()
  @Input() payload: Collection = new Collection()
  @Input() invoice: Invoice = new Invoice()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private collectionService: CollectionService,
    private clientService: ClientService,
    private invoiceCustomerService: InvoiceCustomerService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  ngAfterViewInit(): void {
    
  }

  @Input() collectors: Collector[] = new Array<Collector>()
  public selectedCollector: Collector = new Collector()

  updateOrCreate(){

    let request: any
      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.collectionService.update(body)
      }else{
          //this is create
          request = this.collectionService.create(this.payload)
      }

      request.subscribe(response => {

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)
    })
  }

  public description: string = ""
  open(modalVC: any) {


    console.log(this.customer)

    if(this.customer && this.customer.reg_number && this.customer.connect_id){

        this.onCreditCheckRequest()
    }else{
        this.showCustomerSearch(modalVC)
    }
  }

  showCustomerSearch(modalVC: any){

    this.modalService.open(modalVC, {
        centered: true,
        size: "lg"
      });
  
  }

  public running: string = ""

  updateCustomer(customer: CreditCheckClient){

        let body:any = {}
        body.target = {uuid: this.customer.uuid}
        body.data = {
            reg_number:customer.regNo,
            connect_id: customer.id,
            name: customer.name
        }

        this.running = "btn-loading"

        this.invoiceCustomerService.update(body).subscribe(response => {

            this.running = ""

            if(response && response.status){

                /**
                 * Set Customer reg
                 */
                this.customer.connect_id = customer.id
                this.customer.reg_number = customer.regNo
                this.customer.name = customer.name

                this.modalService.dismissAll()
                this.onCreditCheckRequest();
                this.toastr.success(response.message)
                return;
            }

            this.toastr.error(response.message)
        })
  }

  onCreditCheckRequest(){

    this.running = "btn-loading"

    this.clientService.checkCredit(this.index).subscribe(response => {

      this.running = ""

      if(response.status == "success"){
        this.onSuccessResceived.emit(true)
        return
      }
      this.toastr.error(response.message)
    })
  }

  public companies: Client[] = new Array<Client>()

  public loading: boolean = false
  getCompany(){

        this.loading = true
        this.running = "btn btn-loading"

        this.invoiceCustomerService.findCompany({uuid: this.customer.uuid}).subscribe(response => {

            this.loading = false
            this.running = ""
            this.companies = this.invoiceCustomerService.response(response, false)
        })
  }
}
