import { Injectable } from "@angular/core"
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http'
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";


@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor( private toastrService: ToastrService, private authService: AuthenticationService, private router: Router){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
          catchError(err => {

            console.log("Response:::", err.status)

            if ([422, 401, 403].indexOf(err.status) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              this.router.navigate(['/login']);
    
              // ? Can also logout and reload if needed
              // this._authenticationService.logout();
              // location.reload(true);
            }

            if(err && err.error && err.error.message){
                this.toastrService.error(err.error.message)
            }
          
            // throwError
            const error = err.error.message || err.statusText;

            return throwError(error);

          })
        );
      }
}