import { ClientService } from './client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { Clients } from './client.model';


@Injectable({
  providedIn: 'root'
})
export class ClientAccessor {

    constructor(private clientService: ClientService ){}

    // Private
    private _clients: BehaviorSubject<Clients[] | null> = new BehaviorSubject(null);

     /** * Getter for tags             */
     get clients$(): Observable<Clients[]>
     {
         return this._clients.asObservable();
     }

     getClients(){
        this.clientService.all().subscribe(response => {
            if(response.status=="success"){ this._clients.next(response.data) }
        }, error => "")
     }
}
