<div class="row">

  <div class="col-lg-12 col-md-12 col-sm-12">
          <section id="basic-tabs-components">
                  
                
            <div class="card-body" style="padding: 0;">
              <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li ngbNavItem>
                  <a ngbNavLink>Emails</a>
                  <ng-template ngbNavContent>
                      
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="card card-reminder card-bg-transparant">
                                <div class="card-body text-center" style="padding-top:135px">
                                    <template-preview (onSuccessResceived)="reload($event)" [templates]="emailTemplates" [type]="'Email'" [action]="'create'"></template-preview>
                                </div>
                            </div>
                        </div>
                      
                        <div *ngFor="let item of emails" class="col-lg-3 col-md-4 col-sm-6">
                              <div class="card card-reminder">
                                  <div class="card-header">
                                    <h4>{{ item.name }}</h4>
                                  </div>
                                  <div class="card-body">
                                      
                                      <div class="contetn-wrap" [innerHTML]="item.body"></div>
                                      
                                  </div>
                                  <div class="card-footer">
                      
                                    <template-preview [templates]="emailTemplates" [payload]="item" (onSuccessResceived)="reload($event)" [type]="'Email'" [action]="'update'"></template-preview>
                      
                                  </div>
                              </div>
                        </div>
                    </div>

                  </ng-template>
                </li>
                <li ngbNavItem>
                  <a ngbNavLink>SMS</a>
                  <ng-template ngbNavContent>
                    
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6">
                          <div class="card card-reminder card-bg-transparant">
                              <div class="card-body text-center" style="padding-top:135px">
                                <template-preview [templates]="smsTemplates" (onSuccessResceived)="reload($event)" [type]="'SMS'" [action]="'create'"></template-preview>
                              </div>
                          </div>
                      </div>
                    
                      <div *ngFor="let item of smses" class="col-lg-3 col-md-4 col-sm-6">
                            <div class="card card-reminder">
                                <div class="card-header">
                                  <h4>{{ item.name }}</h4>
                                </div>
                                <div class="card-body">
                                    
                                    <div class="contetn-wrap" [innerHTML]="item.body"></div>
                                    
                                </div>
                                <div class="card-footer">
                    
                                  <template-preview [templates]="smsTemplates" [payload]="item" (onSuccessResceived)="reload($event)" [type]="'SMS'" [action]="'update'"></template-preview>
                    
                                </div>
                            </div>
                      </div>

                    </div>

                  </ng-template>
                </li>
              
              
                
              </ul>
              
              <div [ngbNavOutlet]="nav" class="mt-2"></div>

          <!-- </core-card-snippet> -->
        </div>

        </section>
    </div>
  <!-- End of Card -->
</div>