<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 *ngIf="payload&&!payload.id" class="modal-title" id="exampleModalCenterTitle">Add Template</h5>
    <h5 *ngIf="payload&&payload.id" class="modal-title" id="exampleModalCenterTitle">Update Template</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    
        <div class="row">

          <div [ngClass]="showPreview?'col-lg-6':'col-lg-4'" class="mt-2">

            <h4>Preview</h4>
            <div class="custom-values-holder" *ngIf="!showPreview">
      
              <span *ngFor="let item of placeholders" 
              (click)="insertTextAtCursor(item.key)"
              class="badge badge-default-light badge-default">{{ item.name }} 
              <i class="fa fa-plus-circle"></i></span>
            </div>

            <div class="custom-values-holder mt-4"
                *ngIf="showPreview" 
                [innerHTML]="sortMapData(selectedTemplate.body) || 'Select a Template'">
            </div>

            <div class="col-lg-12 mt-2">
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input"
                   [(ngModel)]="showPreview"
                   (change)="onCheckboxChange($event)"
                    type="checkbox" id="remember-me" tabindex="3" />
                  <label class="custom-control-label" for="remember-me"> Show preview </label>
                </div>
              </div>
            </div>
      
          </div>
            <div [ngClass]="showPreview?'col-lg-6':'col-lg-8'">
      
                <div class="row">

                  <div class="col-lg-12">
                      
                    <app-select
                      [list]="templates"
                      [type]="'text'"
                      [label]="'Template'"
                      [id]="'name'"
                      [value]="selectedTemplate.name"
                      [(data)]="selectedTemplate"
                      (dataChange)="onDataChanged($event)">
                    </app-select>
      
                  </div>

                  <div class="col-lg-5 mt-2">
                    <app-input
                        [type]="'text'"
                        [label]="'Identifiable Name'"
                        [class]="'form-control'"
                        [id]="'name'"
                        [value]="payload.name"
                        [placeholder]="'Identifiable Name'"
                        [(data)]="payload.name">
                    </app-input>
              </div>
              <div class="col-lg-7 mt-2">
                <app-input
                    [type]="'text'"
                    [label]="'Subject'"
                    [class]="'form-control'"
                    [id]="'subject'"
                    [value]="payload.subject"
                    [placeholder]="'Subject'"
                    [(data)]="payload.subject">
                </app-input>
              </div>
      
                  <!-- <div class="col-lg-5">
                    
                    <app-select
                      [list]="schedules"
                      [type]="'text'"
                      [label]="'Add Schedule (Optional)'"
                      [id]="'name'"
                      [value]="selectedSchedule.name"
                      [(data)]="selectedSchedule" >
                    </app-select>
      
                  </div> -->
      
                    <div class="col-lg-12 mt-2">
      
                        <label>Template</label>
                        <div id="editor-container"></div>

                    </div>

                </div>
            </div>

        </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger" rippleEffect>
      <i class="fa fa-save"></i> Cancel
    </button>
    <button type="button" class="btn btn-sm btn-primary" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>


<button *ngIf="action=='update'" class="btn btn-sm btn-primary" (click)="open(modalVC)" rippleEffect>
  <i class="fa fa-edit"></i> Edit
</button>

<button *ngIf="action=='create'" class="btn btn-sm btn-default" (click)="open(modalVC)" rippleEffect>
  <i class="fa fa-plus-circle"></i>  Add
</button>
