<div class="table-responsive" style="min-height: 400px;">
    <table class="table">
      <thead>
        <tr>
          <th>SN</th>
          <th>Status</th>
          <th>Clients</th>
          <th>Amount</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        
          <tr *ngFor="let item of records">
              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">#</span>
                </div>
              </td>

              <td>
                <status [state]="item._state"></status>
              </td>

              <td>
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">#{{ getInvoice(item) }}</div>
                    <div class="font-small-2 text-muted">{{ getClient(item) }}</div>
                  </div>
                </div>
              </td>

              <td>{{ getAmount(item) }}</td>
              <td>{{ item.collection_date }}</td>
              <td>
                <table class="no-padding no-margin pull-right mr-10">
                  <tr>
                    <td>
                      <button class="btn btn-sm btn-primary">Threads</button>
                    </td>
                    <td>
                        <div ngbDropdown>
                          <a ngbDropdownToggle
                            href="javascript:void(0);"
                            class="hide-arrow"
                            id="dropdownTransactions"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
                          </a>
                          <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                            <a href="javascript:void(0)" (click)="updateCollection(item, 'Cancel')" ngbDropdownItem>Cancel</a>
                            <a href="javascript:void(0)" (click)="updateCollection(item, 'Pending')" ngbDropdownItem>Re-Open</a>
                          </div>
                        </div>
                      </td>
                  </tr>
                </table>
              </td>
            
            </tr>

      </tbody>
    </table>
  </div>