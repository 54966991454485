import { NoticeModalComponent } from './notifications/notice/notice.component';
import { PopoversModule } from 'app/main/components/popovers/popovers.module';
import { ChaserTableComponent } from './list/chaser/chaser-table.component';
import { CustomersTableComponent } from './list/customers/customers-table.component';
import { FundingRequestTableComponent } from './list/requests/requests-table.component';
import { CompanyTableComponent } from './list/company/company-table.component';
import { ClientInvoiceTableComponent } from './list/invoices/invoices-table.component';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientTableComponent } from './list/clients/clients-table.component';
import { NgModule } from '@angular/core';

import { AccordionModule } from 'app/main/components/accordion/accordion.module';
import { ReminderUpdateOrCreateComponent } from './reminders/updateOrCreate/update-create.component';
import { QuillModule } from 'ngx-quill';
import { InputComponent } from './forms/input/input.component';
import { TextareaComponent } from './forms/textarea/textarea.component';
import { ToastrModule } from 'ngx-toastr';
import { InvoiceNoteComponent } from './invoices/note/invoice-note.component';
import { InvoiceChaseComponent } from './invoices/chase/invoice-chase.component';
import { InvoiceFinanceComponent } from './invoices/finance/invoice-finance.component';
import { InvoiceDisputeComponent } from './invoices/dispute/invoice-dispute.component';
import { InvoiceCollectComponent } from './invoices/collect/invoice-collect.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputSelectComponent } from './forms/select/select.component';
import { RouterModule } from '@angular/router';
import { RecordStatusComponent } from './status/status.component';
import { DisputeListComponent } from '../dispute/dispute-list/dispute-list.component';
import { FinanceListComponent } from '../finance/list/finance-list.component';
import { CollectionListComponent } from '../collections/collection-list/collection-list.component';
import { ReminderTemplateComponent } from '../reminders/template/template.component';
import { ScheduleListComponent } from '../receivables/schedule/schedule-list.component';
import { InvoiceScheduleComponent } from './invoices/schedule/invoice-schedule.component';
import { FinancerListComponent } from '../finance/financer-list/financer-list.component';
import { InvoiceFinancerComponent } from './invoices/financer/invoice-financer.component';
import { TemplatePreviewComponent } from './reminders/teamplate-preview/template-preview.component';
import { TourComponent } from './notifications/tour/tour.component';
import { RunCheckComponent } from './credit-check/run-check/run-check.component';
import { FinanceEligibleNoticeComponent } from './notifications/finance-eligible/finance-eligible.component';


@NgModule({
  declarations: [
    CompanyTableComponent,
    ClientTableComponent,
    ClientInvoiceTableComponent,
    FundingRequestTableComponent,
    CustomersTableComponent,
    ReminderUpdateOrCreateComponent,
    InputComponent,
    TextareaComponent,
    InvoiceNoteComponent,
    InvoiceChaseComponent,
    InvoiceFinanceComponent,
    InvoiceDisputeComponent,
    InvoiceCollectComponent,
    InputSelectComponent,
    ChaserTableComponent,
    RecordStatusComponent,
    DisputeListComponent,
    FinanceListComponent,
    CollectionListComponent,
    ReminderTemplateComponent,
    ScheduleListComponent,
    InvoiceScheduleComponent,
    FinancerListComponent,
    InvoiceFinancerComponent,
    TemplatePreviewComponent,
    TourComponent,
    NoticeModalComponent,
    RunCheckComponent,
    FinanceEligibleNoticeComponent
  ],
  exports: [
    CompanyTableComponent,
    ClientTableComponent,
    ClientInvoiceTableComponent,
    FundingRequestTableComponent,
    CustomersTableComponent,
    ReminderUpdateOrCreateComponent,
    InputComponent,
    TextareaComponent,
    InvoiceNoteComponent,
    InvoiceChaseComponent,
    InvoiceFinanceComponent,
    InvoiceDisputeComponent,
    InvoiceCollectComponent,
    InputSelectComponent,
    ChaserTableComponent,
    RecordStatusComponent,
    DisputeListComponent,
    FinanceListComponent,
    CollectionListComponent,
    ReminderTemplateComponent,
    ScheduleListComponent,
    InvoiceScheduleComponent,
    FinancerListComponent,
    InvoiceFinancerComponent,
    TemplatePreviewComponent,
    TourComponent,
    NoticeModalComponent,
    RunCheckComponent,
    FinanceEligibleNoticeComponent
  ],
  imports: [
    AccordionModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    PopoversModule,
    CommonModule,
    CoreCommonModule,
    QuillModule.forRoot(),
    NgbModule,
    RouterModule
  ],
})
export class CommonComponentsModule {}
