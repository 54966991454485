import { UtilService } from './../../../../services/utils.service';
import { Company } from 'app/main/account/connect/connect.model';

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as snippet from 'app/main/components/tabs/tabs.snippetcode';
import { CompanyAccessor } from './../../../../services/company/company.accessor';
import { CompanyService } from 'app/services/company/company.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.scss']
})
export class CompanyTableComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public isAdmin: boolean = true;
    @Input() companies: Company[];
    
    constructor(
      private companyService: CompanyService,
      private companyAccessor: CompanyAccessor,
      private utilsService: UtilService
      ){

    }

    ngOnInit(): void {
        
        
    }

    sum(data: Array<any>, column: string){

      if(! data || data.length == 0){
          return 0;
      }

      let sum: number = 0
      data.forEach(element => {

          sum += parseFloat(this.utilsService.sumJsonData(element.invoices, column)) 
      })
      return sum.toFixed(2);
    }

    ngOnDestroy(): void {
        
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
    }
}