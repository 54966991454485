
<div class="form-group">
    <label *ngIf="label!=''" class="form-label"> {{ label }}</label>

    <quill-editor
      name="addDescription"
      customToolbarPosition="'bottom'"
      [(ngModel)]="data"
      (ngModelChange)="dataChange.emit(data)"
      id="{{id}}"
      class="border-bottom-0 editor-container"
      placeholder="{{ placeholder }}">
      <div quill-editor-toolbar>
        <div class="d-flex justify-content-end desc-toolbar border-top-0">
          <span class="ql-formats mr-0">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-align"></button>
            <button class="ql-link"></button>
          </span>
        </div>
      </div>
    </quill-editor>

  </div>
