import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { InvoiceNote } from 'app/main/receivables/receivable.data';
import { InvoiceNoteService } from 'app/services/invoices/invoice.service';
import { Invoice } from 'app/services/invoices/invoice.model';

@Component({
  selector: 'invoice-note',
  templateUrl: './invoice-note.component.html',
  styleUrls: ['./invoice-note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceNoteComponent implements OnInit {

  // public
  @Input() type: string = ""
  @Input() payload: InvoiceNote = new InvoiceNote()
  @Input() invoice: Invoice = new Invoice()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private invoiceNoteService: InvoiceNoteService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  updateOrCreate(){

    this.payload.invoice_id = this.invoice.id

    let request: any = this.invoiceNoteService.create(this.payload)

      request.subscribe(response => {

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }

        this.toastr.error(response.message)
    })
  }

  public description: string = ""
  open(modalVC: any) {

    this.modalService.open(modalVC, {
      centered: true
    });
  }
}
