import { Clients } from '../../../../services/clients/client.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { InvoiceChaser } from 'app/main/receivables/receivable.data';
import { InvoiceChaseService } from 'app/services/invoices/invoice.service';
import { Invoice } from 'app/services/invoices/invoice.model';


@Component({
  selector: 'chaser-table',
  templateUrl: './chaser-table.component.html',
  styleUrls: ['./chaser-table.component.scss']
})
export class ChaserTableComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public isAdmin: boolean = true;
  public clients: Clients[];
    
    constructor(
      private chaserService: InvoiceChaseService
      ){

    }
  

    ngOnInit(): void {
        
       this.loadChaser()
    }

    public records: InvoiceChaser[] = new Array<InvoiceChaser>()
    loadChaser(){
        this.chaserService.all().subscribe(response => {
            this.records = this.chaserService.response(response, false)
        })
    }

    getAmount(data: InvoiceChaser){
        return data.invoice.total_amount
    }

    getClient(data: InvoiceChaser){
        return data.invoice.client.name
    }

    getInvoice(data: InvoiceChaser){
        return data.invoice.doc_number
    }

    ngOnDestroy(): void {
        
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
    }
}