import { Clients } from '../../../../services/clients/client.model';
import { ClientAccessor } from '../../../../services/clients/client.accessor';
import { ClientService } from '../../../../services/clients/client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as snippet from 'app/main/components/tabs/tabs.snippetcode';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'requests-table',
  templateUrl: './requests-table.component.html',
  styleUrls: ['./requests-table.component.scss']
})
export class FundingRequestTableComponent implements OnInit, OnDestroy {


  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public isAdmin: boolean = true;
  public clients: Clients[];
    
    constructor(
      private clientService: ClientService,
      private clientAccessor: ClientAccessor
      ){

    }
  

    ngOnInit(): void {
        
        this.clientAccessor.clients$.pipe(takeUntil(this._unsubscribeAll))
        .subscribe((clients: Clients[]) => {
            this.clients = clients;

            console.log("Clients List: ", clients)
        });

        /** Initializers  */
        this.clientAccessor.getClients();
    }

    ngOnDestroy(): void {
        
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
    }
}