
<invoice-schedule [type]="'create'" [page]="'receivable'" (onSuccessResceived)="load($event)"></invoice-schedule>
<div class="table-responsive" style="min-height: 400px;">
    <table class="table">
      <thead>
        <tr>
          <th>SN</th>
          <th>Status</th>
          <th>Name</th>
          <th>Frequency</th>
          <th>Days Before</th>
          <th>Start Days After</th>
          <th>End Days After</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        
          <tr *ngFor="let item of schedules">
              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">#</span>
                </div>
              </td>

              <td>
                  <status [state]="item._state"></status>
              </td>

              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{ item.name }}</span>
                </div>
              </td>

              <td>{{ item.frequency }}</td>

              <td>{{ item.start_before }} Days</td>
              <td>{{ item.start_offset }} Days</td>
              <td>{{ item.end_offset }} Days</td>
              
              <td>

                <invoice-schedule [payload]="item" [page]="'company'" [type]="'update'" (onSuccessResceived)="load($event)"></invoice-schedule>
                
              </td>
            
            </tr>

      </tbody>
    </table>
  </div>