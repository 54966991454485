export let agreement = `
<h4>Fintab Invoice Sale and Financing Agreement</h4>

<strong>Effective Date: [Date]</strong>
<p>This Agreement ("Agreement") is between [Customer's Full Name/Company Name] ("Customer") and Fintab ("Fintab"). By signing, the Customer agrees to sell eligible invoices to Fintab, transferring full ownership and rights to Fintab at a discounted rate.</p>

<ol>
    <li><strong>Invoice Sale:</strong> The Customer agrees to transfer all rights to the eligible invoice to Fintab, who will then have the sole right to collect the full invoice amount from the Debtor.</li>
    <li>
    <strong>Purchase Price:</strong> Fintab will pay the Customer a discounted amount of the total invoice value within [number] business days after approval.
    </li>
    <li>
    <strong>Responsibilities:</strong>
        <ul>
            <li>Fintab will collect the full value from the Debtor.</li>
            <li>The Customer must provide necessary documentation and notify the Debtor of the transfer.</li>
        </ul>
    </li>
    <li>
    <strong>Warranties:</strong>
    <ul>
        <li>The Customer guarantees the invoice is valid, enforceable, and free of any claims or liens.</li>
    </ul>
    </li>
    <li>
    <strong>Default: </strong>If the Debtor fails to pay, Fintab may seek recourse against the Customer for any losses, including legal fees.
    </li>
    <li>
    <strong>Termination:</strong> Either Party may terminate this Agreement with [number] days' notice, but all previously sold invoices remain under the Agreement's terms.
    </li>
    <li>
    <strong>Governing Law: </strong>This Agreement is governed by the laws of England and Wales.
    </li>
</ol>
<p>This Agreement represents the full understanding between the Parties.</p>
`