import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { StateTypes, invoiceChaseFrequencies, invoiceChaseStates } from './chase.data';
import { InvoiceChaseService, ScheduleService } from 'app/services/invoices/invoice.service';
import { InvoiceChaser, Schedule } from 'app/main/receivables/receivable.data';
import { Invoice } from 'app/services/invoices/invoice.model';

@Component({
  selector: 'invoice-chase',
  templateUrl: './invoice-chase.component.html',
  styleUrls: ['./invoice-chase.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceChaseComponent implements OnInit {

  // public
  @Input() type: string = ""
  @Input() stage: string = ""
  @Input() payload: InvoiceChaser = new InvoiceChaser()
  @Input() invoice: Invoice = new Invoice()
  @Output() onSuccessResceived = new EventEmitter<any>()

  @Input() schedules: Schedule[] = new Array<Schedule>()
  public selectedSchedule: Schedule = new Schedule()

  constructor(
    private modalService: NgbModal,
    private chaseService: InvoiceChaseService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  public running: string = ""
  updateOrCreate(){

    this.running = "btn-loading"

    if(this.selectedOption.id == 1){
        this.payload.schedule_id = 0;
    }else{
      this.payload.schedule_id = this.selectedSchedule.id
    }
    
    this.payload.invoice_id = this.invoice.id

    if(this.invoice._chase == "Chasing"){
        this.payload._state = "Pause"
    }else{
      this.payload._state = "Chasing"
    }

    let request = this.chaseService.create(this.payload)
      request.subscribe(response => {

        this.running = ""

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)
    }, error => {
      this.toastr.error("Unable to complete request")
    })
  }

  public description: string = ""
  open(modalVC: any) {

    if(this.type == 'link'){
        this.modalService.open(modalVC, {
          size: "sm",
          centered: true
        });
        return
    }

    if(this.invoice._chase == "Chasing"){
      this.updateOrCreate()
    } else if(this.invoice.chaser){
      this.invoice._chase = "Pause"
      this.updateOrCreate()
    } else {
      if(this.invoice.chaser){
        this.invoice._chase = "Pause"
        this.updateOrCreate()
      }else{
        this.modalService.open(modalVC, {
          size: "sm",
          centered: true
        });
      }
  }
  }

  public options: StateTypes[] = [
      {id: 1, name: "Use Automated Schedule", key: "Default"},
      {id: 2, name: "Use Defined Schdule", key: "Defined"},
      {id: 3, name: "Create Custom Schedule", key: "Custom"},
  ]

  public selectedOption:StateTypes = new StateTypes()

  public showState = 0;
  onOptionChanged($event:StateTypes){

      switch($event.key){
          case "Default": this.showState = 0; break;
          case "Defined": this.showState = 1; break;
          case "Custom": this.showState = 2; break;
      }
  }

  public showPreview = false

  public placeholders:StateTypes[] = //new Array<StateTypes>()
  [
    {"id": 1, "name": "Customer: Name", "key": "<customer_name>"},
    {"id": 2, "name": "Recipient: First name", "key": "<recipient_firstname>"},
    {"id": 3, "name": "Recipient: Surname", "key": "<recipient_surname>"},
    {"id": 4, "name": "Recipient: Full name", "key": "<recipient_fullname>"},
    {"id": 5, "name": "Sender: First name", "key": "<sender_firstname>"},
    {"id": 6, "name": "Sender: Surname", "key": "<sender_surname>"},
    {"id": 7, "name": "Sender: Full name", "key": "<sender_fullname>"},
    {"id": 8, "name": "Customer: Payment Portal", "key": "<customer_payment_portal>"},
    {"id": 9, "name": "Due date", "key": "<due_date>"},
    {"id": 10, "name": "Invoice: Issue date", "key": "<invoice_issue_date>"},
    {"id": 11, "name": "Invoice: Reference number", "key": "<invoice_reference_number>"},
    {"id": 12, "name": "Invoice: Reference number - with link", "key": "<invoice_reference_number_with_link>"},
    {"id": 13, "name": "Invoice: Total", "key": "<invoice_total>"},
    {"id": 14, "name": "Invoice: Amount owed", "key": "<invoice_amount_owed>"},
    {"id": 15, "name": "Days overdue", "key": "<days_overdue>"},
    {"id": 16, "name": "Weeks overdue", "key": "<weeks_overdue>"},
    {"id": 17, "name": "Months overdue", "key": "<months_overdue>"},
    {"id": 18, "name": "Days until due date", "key": "<days_until_due_date>"},
    {"id": 19, "name": "Weeks until due date", "key": "<weeks_until_due_date>"},
    {"id": 20, "name": "Months until due date", "key": "<months_until_due_date>"}
]
}
       