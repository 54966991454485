import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

    constructor(){}
    

    /**
     * This method accept an array and a string column
     * It search the array and sum up the value in the column matched
     * 
     * Note: this should only be used if the value in the column is an int or integer else it will return 0
     * 
     * @param data 
     * @param key 
     * @returns 
     */
    sumJsonData(data:Array<any> , key: string){

        try{
            return data.map(bill => bill[key]).reduce((acc, amount) => acc + amount);
        }catch(e){
            return 0
        }
    }

    getDateXDaysAgo(date: any ){

        if (typeof date !== 'object') {
            date = new Date(date);
        }
        let now:any = new Date();
        var seconds = Math.floor((now - date) / 1000);
        let interval = Math.floor(seconds / 86400);
        
        if(interval > 1){
            return `${interval} days`;
        }
        return `${interval} day`;
    }

    timeAgo(date) {

        if (typeof date !== 'object') {
          date = new Date(date);
        }

        let now:any = new Date();

        var seconds = Math.floor((now - date) / 1000);
        var intervalType;
      
        var interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
          intervalType = 'year';
        } else {
          interval = Math.floor(seconds / 2592000);
          if (interval >= 1) {
            intervalType = 'month';
          } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
              intervalType = 'day';
            } else {
              interval = Math.floor(seconds / 3600);
              if (interval >= 1) {
                intervalType = "hour";
              } else {
                interval = Math.floor(seconds / 60);
                if (interval >= 1) {
                  intervalType = "minute";
                } else {
                  interval = seconds;
                  intervalType = "second";
                }
              }
            }
          }
        }
      
        if (interval > 1 || interval === 0) {
          intervalType += 's';
        }
      
        return interval + ' ' + intervalType;
      };

      
      response(response: any, withData: boolean = false): {status: boolean, data?: any} {
        if (response.status === "success") {
            if (withData && response.data) {
                return { status: true, data: response.data };
            }
            return { status: true };
        }
        return { status: false };
    }

    getChars(str: string, length:number = 2){

      let matches:any = str.match(/\b(\w)/g); // ['J','S','O','N']
      let acronym = matches.join('');
  
      return acronym.slice(0,length)
    }
  
    findBy(data:any, name:string, key: string){
  
      let value = data.filter(x => {
        return x[key] == name
      })
      return value[0] || data[0];
  }
  
    find(data:any, id:number ){
  
        let value = data.filter(x => {
          return x.id == id
        })
        return value[0] || data[0];
    }
  
    sumNumberArray(numbers: number[]){
      try{
        let total = numbers.reduce((acc, currentValue) => acc + currentValue, 0);
        return parseFloat(total.toFixed(2))
      }catch(e){
        return 0
      }
    }
  
    toMoneyFormat(number: number = 0){
      
      // Format the number with commas for thousands and two decimal places
      const formattedNumber = number.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  
      return formattedNumber;
    }
  
    emailValidate(email: string){
  
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
  
    }
  
    generateRandomCode(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
      }
    
      return code;
    }
  
    getRandomColor(isRandomColor: boolean  = false) {
  
  
        if(!isRandomColor){
            return this.getDefinedColors();
        }
  
  
      var letters = '0123456789ABCDEF';
      var color = '#';
    
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    
      return color;
    }
  
    getDefinedColors(){
  
        const colors = [
                    '#6c5ffc', '#db39e2', '#df8129',
                    '#815f40', '#05c3fb', '#32d5bd', 
                    '#fc5296', '#550726', '#1170e4', 
                    '#2f702b', '#813eeb', '#d81342', 
                    '#495057','#ffc107', '#05c3fb'
                  ];
  
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
     
    }
  
    getDayAndMonthName(mysqlDate: string){
  
      // Convert the MySQL date string to a JavaScript Date object
      const date = new Date(mysqlDate);
  
      // Array of month names
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
  
      // Get the month index (0-11) from the Date object
      const monthIndex = date.getMonth();
  
      // Get the day and formatted month name
      const day = date.getDate();
      const formattedMonth = monthNames[monthIndex];
  
      // Create the formatted output (e.g., "Oct 15")
      const formattedDate = `${day} ${formattedMonth}`;
  
      return formattedDate; // Output: "Oct 15"
    }
  
    getAMPMDateTime(mysqlDate: string){
  
      const currentTime = new Date(mysqlDate);
  
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
  
      // Determine whether it's AM or PM
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
      // Convert to 12-hour format
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  
      // Create the formatted time string (e.g., "2:30 PM")
      const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
  
      return formattedTime; // Output: "2:30 PM"
    }
}