import { Injectable } from '@angular/core';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root'
})
export abstract class RequestService {

    constructor(protected appService: AppService){}

    public FETCH_PATH       =  `store/fetch/products`;
    public ALL_PATH         =  `store/list/products`;
    public FIND_PATH        =  `store/find/products`;
    public UPDATE_PATH      =  `store/update/products`;
    public CREATE_PATH      = `store/create/products`;
    public DELETE_PATH      = `store/delete/products`;

    first(){
        return this.appService.get(this.FETCH_PATH+`/first`)
    }

    last(){
        return this.appService.get(this.FETCH_PATH+`/last`)
    }

    fetch(uuid:any){
        return this.appService.get(this.FETCH_PATH+`/${uuid}`)
    }

    all(){
        return this.appService.get(this.ALL_PATH)
    }

    find(params: any){
        return this.appService.post(this.FIND_PATH, params)
    }

    update(params: any){
        return this.appService.post(this.UPDATE_PATH, params)
    }

    create(params: any){
        return this.appService.post(this.CREATE_PATH, params)
    }

    delete(id:number){
        return this.appService.get(this.DELETE_PATH+`/${id}`)
    }

    /**
     * Special method response
     */
    response(response: any, notify: boolean){
        if(!response){ return [] }
        if(response.status == "success"){
            
            if(response.data){
                return response.data
            }
            return []
        }
        return []
      }

    isSuccess(response:any, callback:any): boolean{
        try{
        if(!response){ callback(true, "Request failed to complete"); return; }
            let message = response.message
            if(response.status == "success"){
                callback(true, message)
            }else{
                callback(false, message)
            }
        }catch(e){
            callback(false, "Failed response")
        }
    }

}