import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { Collection, Collector } from 'app/main/collections/collection.data.';
import { Invoice } from 'app/services/invoices/invoice.model';
import { CollectionService } from 'app/services/collections/collection.service';

@Component({
  selector: 'invoice-collect',
  templateUrl: './invoice-collect.component.html',
  styleUrls: ['./invoice-collect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceCollectComponent implements OnInit {

  // public
  @Input() type: string = ""
  @Input() payload: Collection = new Collection()
  @Input() invoice: Invoice = new Invoice()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private collectionService: CollectionService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  ngAfterViewInit(): void {
    
    this.getInvoiceAmount()
  }

  getInvoiceAmount(){
      try{

        this.payload.amount = this.invoice.total_amount;
        console.log("checkinvoid", this.invoice)
      }catch(e){ return 0; }
  }

  @Input() collectors: Collector[] = new Array<Collector>()
  public selectedCollector: Collector = new Collector()

  updateOrCreate(){

    this.payload.invoice_id = this.invoice.id
    this.payload.collector_id = this.selectedCollector.id

    let request: any
      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.collectionService.update(body)
      }else{
          //this is create
          request = this.collectionService.create(this.payload)
      }

      request.subscribe(response => {

        // console.log("response::: ", response, response.status)

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)
    })
  }

  public description: string = ""
  open(modalVC: any) {

    this.modalService.open(modalVC, {
      centered: true
    });
  }
}
