import { Role } from "app/auth/models";

export class User {

    id!: number;
    company_id!: number;
    name!: string;
    email!: string;
    phone!: string;
    outlet_id!: number;
    role_id!:number;
    status!: number;
    identifier!: string;
    _state!: number;
    color!: string
    created_at!: string;
    updated_at!: string;
    role: Role;
}

export class OAuth extends User{
    expires_in!: number;
    token!: string 
    token_type!: string
    user!: OauthUser
    avatar:string =  'avatar-s-11.jpg'
}
export class OauthUser{
    id!: number 
    name!: string 
    identifier!: string 
    is_admin!: number
    company!: Company
}
export class Company
{
    identifier!: string
    name!: string
    address!: string
    country!: string
    plan!: Plan
    city!: string
    postcode!: string 
    phone!: string
}

export class Plan {
    expired!: boolean
    days!: number
    verified!: boolean
}

export class CompanyPayment {
    id!: number
    uuid!: string
    company_id!: number
    swift!: string
    account_number!: string
    iban!: string
    bank_name!: string
    country!: string
    status!: string
}

export class Billable {
    id!: number
    uuid!: string
    company_id!: number
    name!: string
    amount!: string
    discount!: number
    description!: string 
    status!: string
}
