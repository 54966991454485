import { CustomerService } from './../../../../services/customers/customer.service';
import { UtilService } from './../../../../services/utils.service';
import { state } from '@angular/animations';
import { CustomTemplateService, ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from 'app/services/invoices/invoice.service';
import { StateTypes } from '../chase/chase.data';
import { Schedule } from 'app/main/receivables/receivable.data';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'invoice-schedule',
  templateUrl: './invoice-schedule.component.html',
  styleUrls: ['./invoice-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceScheduleComponent implements OnInit {

  // public
  @Input() page: string = ""
  @Input() type: string = ""
  @Input() payload: Schedule = new Schedule()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private scheduleService: ScheduleService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private customTemplateService: CustomTemplateService,
    private authService: AuthenticationService
    ) {}
  /**
   * On init
   */
  ngOnInit() {


      this.loadCustomTemplate()
      this.isAdmin()
  }

  public frequencies: StateTypes[] = [
    {id: 1, name: "Weekly", key: "Weekly"},
    {id: 2, name: "Bi-Weekly", key: "Bi-Weekly"},
    {id: 3, name: "Monthly", key: "Monthly"},
    {id: 4, name: "Yearly", key: "Yearly"}
  ];

  public types: StateTypes[] = [
    {id: 1, name: "Default", key: "Default"},
    {id: 2, name: "Normal", key: "Normal"}
  ];

  public dueStages: StateTypes[] = 
  [
      {id: 1, name: "Custom Setting", key: "Custom", data: "0:0"},
      {id: 2, name: "7 Days Before Due", key: "Pending", data: "7:4"},
      {id: 3, name: "3 Days Before Due", key: "Upcoming", data: "3:0"},
      {id: 4, name: "Due Date", key: "Due", data: "0:0"},
      {id: 5, name: "1 - 6 Day Overdue", key: "Grace", data: "1:6"},
      {id: 6, name: "7 -13 Days Overdue", key: "Late", data: "7:13"},
      {id: 7, name: "14 - 29 Days Overdue", key: "Delayed", data: "14:29"},
      {id: 8, name: "30 - 59 Days Overdue", key: "Behind", data: "30:59"},
      {id: 9, name: "60 - 89 Days Overdue", key: " Critical", data: "60:89"},
      {id: 10, name: "90+ Days Overdue", key: "Collection", data: "90:1000"}
];

public showDayRange: boolean = false;

onStateDueChanged($event: StateTypes){

    let data = $event.data.split(":")
    this.payload.days_from = Number(data[0])
    this.payload.days_to = Number(data[1])

    console.log("Testing:::: ", $event)

    this.selectedDueStages = $event;

    if($event.key == 'Custom'){
        this.showDayRange = true;
    }else {
        this.showDayRange = false;
    }
}

isAdmin(){
    if(!this.authService.isAdmin){
        this.types = [{id: 2, name: "Normal", key: "Normal"}]
    }
}
public selectedDueStages: StateTypes = new StateTypes()

  public selectedTemplate: Template = new Template() 
  public templates: Template[] = new Array<Template>()
  loadCustomTemplate(){
      this.customTemplateService.all().subscribe(response => {
          this.templates = this.customTemplateService.response(response, false)
      })
  }

  public selectedStates: StateTypes = new StateTypes()
  public selectedFrequency: StateTypes = new StateTypes()

  updateOrCreate(){

    this.payload.frequency = this.selectedFrequency.name;

    if(!this.selectedTemplate.id){
        this.toastr.warning("Please select a template for this schedule");
        return;
    }

    this.payload.template_id = this.selectedTemplate.id;
    this.payload._type = this.selectedDueStages.key;
    this,this.payload._state = this.selectedStates.name;

    let request: any
      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.scheduleService.update(body)
      }else{
          //this is create
          request = this.scheduleService.create(this.payload)
      }

      request.subscribe(response => {

        this.scheduleService.response(response, true)
        this.scheduleService.isSuccess(response, (status, message) => {
            if(status){
              this.modalService.dismissAll()
              this.onSuccessResceived.emit(true)
              return;
            }
            this.toastr.error(response.message)
        })
    })

  }

  public description: string = ""
  open(modalVC: any) {

    this.selectedFrequency = this.utilService.findBy(this.frequencies, "frequency", this.payload.frequency)

    console.log("Logger", this.selectedFrequency)

    this.modalService.open(modalVC, {
      centered: true
    });
  }
}
