import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Dispute } from 'app/main/dispute/dispute.data';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Collection } from '../collection.data.';
import { CollectionService } from 'app/services/collections/collection.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent implements OnInit, OnDestroy {

  @Output() onResponseReceived = new EventEmitter<any>()
  // public
  public data: any;
  public contentHeader: object;
  @Input() records: Collection[] = new Array<Collection>()

  Monthly = false;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {PricingService} _pricingService
   */
  constructor(
    private collectionService: CollectionService,
    private toastr: ToastrService
    ) {
    this._unsubscribeAll = new Subject();
  }


  ngOnInit(): void {
    
  }

  getAmount(data: Collection){
    return data.invoice.total_amount
  }
  
  getClient(data: Collection){
      return data.invoice.client.name
  }
  
  getInvoice(data: Collection){
      return data.invoice.doc_number
  }

  updateCollection(data: Collection, key: string){

      const body: any = {}
      body.data = {_state: key}
      body.target = {uuid: data.uuid}

      this.collectionService.update(body).subscribe(response => {

          console.log("request collection", response)
          this.collectionService.isSuccess(response, (status, message) => {
              if(status){
                  this.toastr.success(message);
                  this.onResponseReceived.emit(true)
              }else{
                this.toastr.error(message)
              }
          })
      })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
