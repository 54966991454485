import { InvoiceService } from './invoice.service';
import { Invoice } from './invoice.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Sync } from 'app/main/account/connect/connect.model';


@Injectable({
  providedIn: 'root'
})
export class InvoiceAccessor {

    constructor( private invoiceService: InvoiceService ){}

    // Private
    private _invoice: BehaviorSubject<Invoice[] | null> = new BehaviorSubject(null);
    private _sync: BehaviorSubject<Sync> = new BehaviorSubject(null);
    private _lastsync: BehaviorSubject<Sync> = new BehaviorSubject(null);

     /** * Getter for tags             */
     get invoices$(): Observable<Invoice[]>
     {
         return this._invoice.asObservable();
     }


     get synchronise$(): Observable<Sync>
     {
         return this._sync.asObservable();
     }

     get lastSync$(): Observable<Sync>
     {
         return this._lastsync.asObservable();
     }

     getInvoice(){
        this.invoiceService.all().subscribe(response => {
            if(response.status=="success"){ this._invoice.next(response.data) }
        }, error => "")
     }

     synchronize(options){

        this.invoiceService.sync(options).subscribe(response => {
            if(response.status=="success")
            { this._sync.next(response.data) }
        }, error => "")
     }

     lastSync(){
        this.invoiceService.last().subscribe(response => {
            if(response.status=="success")
            { this._lastsync.next(response.data) }
        }, error => "")
     }
}
