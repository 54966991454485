<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 *ngIf="type=='create'" class="modal-title" id="exampleModalCenterTitle">Add Template</h5>
    <h5 *ngIf="type=='update'" class="modal-title" id="exampleModalCenterTitle">Update Template</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    

    <div class="row">
        
        <div class="col-lg-12">
          <app-input
              [type]="'text'"
              [label]="'Subject'"
              [class]="'form-control'"
              [id]="'subject'"
              [value]="payload.subject"
              [placeholder]="'Subject'"
              [(data)]="payload.subject">
          </app-input>
        </div>
        <div class="col-lg-12">

          <app-textarea
              [type]="'text'"
              [label]="'Description'"
              [class]="'form-control'"
              [id]="'description'"
              [value]="payload.message"
              [placeholder]="'Write Something...'"
              [(data)]="payload.message">
          </app-textarea>
          <!-- end quild -->

        </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>

<a (click)="open(modalVC)" href="javascript:void(0)" ngbDropdownItem>Add Note</a>