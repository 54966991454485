
<ng-template #showingCompanySearch let-modal>
    <div class="modal-header">
      <h5  class="modal-title" id="exampleModalCenterTitle">
        <button (click)="getCompany()" style="margin-right:20px" class="btn btn-sm btn-danger {{ running }}">Run Search</button> 
        Run Credit Check 
    </h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      
        <div *ngIf="companies.length == 0" style="padding:90px 0;text-align: center;" class="">
            <span *ngIf="!loading">No records found, click the button above to search...</span>
            <span *ngIf="loading">Loading...</span>
        </div>

        <div class="row match-height" *ngIf="companies.length > 0">
            <!-- Company Table -->
            <div class="col-lg-12 col-12 col-no-padding">
              <div class="card card-company-table">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Actions</th>
                                        <th>Company</th>
                                        <th>Registration</th>
                                        <th>Address</th>
                                        <th>Status</th>
                                    </tr>
                            </thead>
                                <tbody>
                            

                                        <tr *ngFor="let company of companies;let i = index">
                                            <td class="">{{ i + 1}}</td>
                                            <td class="text-nowrap">
                                                <button (click)="updateCustomer(company)" class="btn btn-sm btn-primary">Run Check</button>
                                            </td>
                                            <td class="text-nowrap">{{ company.name }}</td>
                                            <td class="text-nowrap">{{ company.regNo }}</td>
                                            <td class="text-nowrap">{{ company.country }}</td>
                                            <td class="text-nowrap">{{ company.status }}</td>
                                        </tr>


                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
  
   </div>

   </div>
    
  </ng-template>

<button type="button" (click)="open(showingCompanySearch)" class="btn btn-primary {{ running }}" rippleEffect>Run Credit Check</button>