import Quill from 'quill';

// Define the custom span blot
const Inline = Quill.import('blots/inline');

class SpanBlot extends Inline {

  node:any = ""
  static blotName = 'custom-span';  // Name of the blot
  static tagName = 'span';  // HTML tag used for the blot

  static create(value: any) {
    let node = super.create();
    node.innerHTML = value;  // Set the inner HTML of the span
    return node;
  }

  static formats(node: any) {
    return node.innerHTML;  // Retrieve the HTML content of the span
  }

  format(name: string, value: any) {
    if (name === 'custom-span') {
      this.node.innerHTML = value;  // Update the HTML content
    } else {
      super.format(name, value);
    }
  }
}

// Register the custom blot with Quill
Quill.register(SpanBlot);
