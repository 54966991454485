import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Dispute } from 'app/main/dispute/dispute.data';
import { Subject } from 'rxjs';
import { Schedule } from '../receivable.data';

@Component({
  selector: 'schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public contentHeader: object;
  @Input() schedules: Schedule[] = new Array<Schedule>()
  @Output() onSuccessResceived = new EventEmitter<any>()

  Monthly = false;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {PricingService} _pricingService
   */
  constructor() {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    
  }

  load($event){
      this.onSuccessResceived.emit(true)
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
