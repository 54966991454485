<div class="row match-height">
    <!-- Company Table -->
    <div class="col-lg-12 col-12 col-no-padding">
      <div class="card card-company-table">
        <div class="card-body p-0">
          <div class="table-responsive" style="min-height: 400px;">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Invoices</th>
                  <th>Clients</th>
                  <th>Value</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of records;let index = i">
                  <td class="text-nowrap">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bolder mb-25">{{ i+1 }}</span>
                    </div>
                  </td>
                  <td>#{{ getInvoice(item) }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">{{ getClient(item) }}</div>
                      </div>
                    </div>
                  </td>

                  <td class="text-nowrap">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bolder mb-25">£{{ getAmount(item) }}</span>
                    </div>
                  </td>
                  <td>{{ item._state }}</td>
                  <td class="text-nowrap clear-styles">
                      <table class="no-padding no-margin pull-right mr-10">
                            <tr>
                              <td>
                                <button class="btn btn-danger btn-sm">View</button>
                              </td>
                              <td>
                                <div ngbDropdown>
                                  <a ngbDropdownToggle
                                    href="javascript:void(0);"
                                    class="hide-arrow"
                                    id="dropdownTransactions"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
                                  </a>
                                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                                    <a href="javascript:void(0)" ngbDropdownItem>Stop</a>
                                    <a href="javascript:void(0)" ngbDropdownItem>Start</a>
                                    <a href="javascript:void(0)" ngbDropdownItem>Pending</a>
                                    <a href="javascript:void(0)" ngbDropdownItem>Delete</a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>

                  </td>
                
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Company Table -->

    <!-- Transactions Card -->
  </div>