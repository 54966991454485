<div class="modal-primary d-inline-block">
    <!-- Button trigger modal -->
    <button type="button"
      class="btn btn-primary"
      (click)="modalOpen(modalSuccess)"
      rippleEffect>Get Started</button>

    <!-- Modal -->
    <ng-template #modalSuccess let-modal>
        <!-- <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel160">Get Started</h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div> -->
        <div class="modal-body" tabindex="0" ngbAutofocus>
           
           <div class="" style="padding:0 40px">

            <h2 class="mt-3 mb-2" id="myModalLabel160">Get Started</h2>

            <p >Hi <span *ngIf="currentUser.user">{{ currentUser.user.name }}</span>,</p>

            <p><strong>Welcome to PIPO!</strong> We’re excited to have you here.</p>

            <p>Let’s get started—explore, connect, and make the most of everything PIPO has to offer!</p>

            <p>Cheers,<br/>
            The PIPO Team</p>
        
            </div>
            
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Next
            </button>
        </div>
        </ng-template>
    <!-- / Modal -->
  </div>