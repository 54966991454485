<ng-template #modalVC let-modal>
  <div class="modal-header" *ngIf="templates.length > 0">
    <h5 *ngIf="type=='create'" class="modal-title" id="exampleModalCenterTitle">Add Schedule</h5>
    <h5 *ngIf="type=='update'" class="modal-title" id="exampleModalCenterTitle">Update Schedule</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    
    <div class="row">

      <div class="col-lg-12" style="padding:30px" *ngIf="templates.length == 0">
        <h2>No Template Available</h2>
        <div>

          <p>It appears you don't have any templates set up yet. Please follow these steps:</p>
          <ol>
            <li>Click on the Template tab to create a new template.</li>
            <li>Create a schedule and attach the newly created template to your schedule.</li>
          </ol>
          
          <!-- <a href="javascript:;" (click)="modal.dismiss('Cross click')" routerLink="/reminders/list" class="btn btn- btn-primary">Create New Schedule</a> -->
          
        </div>
    </div>
           
      <div class="col-lg-12" *ngIf="templates.length > 0">

              <div class="row">
                    <div class="col-lg-8 mt-2">
                      <app-input
                          [type]="'text'"
                          [label]="'Schedule Name'"
                          [class]="'form-control'"
                          [id]="'name'"
                          [value]="payload.name"
                          [placeholder]="'Schedule Name'"
                          [(data)]="payload.name">
                      </app-input>
                </div>

                <div class="col-lg-4">
                  <app-select
                      [list]="frequencies"
                      [type]="'text'"
                      [id]="'name'"
                      [label]="'Schedule Type'"
                      [value]="selectedFrequency.name"
                      [placeholder]="'Schedule Type'"
                      [(data)]="selectedFrequency">
                  </app-select>
                </div>

                <div class="col-lg-8">
                  <app-select
                      [list]="templates"
                      [type]="'text'"
                      [label]="'Attach Template'"
                      [id]="'name'"
                      [value]="selectedTemplate.name"
                      [placeholder]="'Frequency'"
                      [(data)]="selectedTemplate">
                  </app-select>
                </div>

                <div class="col-lg-4">
                  <app-select
                      [list]="types"
                      [type]="'text'"
                      [id]="'name'"
                      [label]="'Schedule States'"
                      [value]="selectedStates.name"
                      [placeholder]="'Schedule States'"
                      [(data)]="selectedStates">
                  </app-select>
                </div>

                <div class="col-lg-6">
                  <app-select
                      [list]="dueStages"
                      [type]="'text'"
                      [id]="'name'"
                      [label]="'Schedule Stages'"
                      (dataChange)="onStateDueChanged($event)"
                      [value]="selectedDueStages.name"
                      [placeholder]="'Schedule Stages'"
                      [(data)]="selectedDueStages">
                  </app-select>
                </div>

                <div class="col-lg-3 mt-2" *ngIf="showDayRange">
                    <app-input
                        [type]="'number'"
                        [label]="'Days From'"
                        [class]="'form-control'"
                        [id]="'subject'"
                        [value]="payload.days_from"
                        [placeholder]="'Days From'"
                        [(data)]="payload.days_from">
                    </app-input>
                </div>
                
                <div class="col-lg-3 mt-2" *ngIf="showDayRange">
                  <app-input
                      [type]="'number'"
                      [label]="'Days To'"
                      [class]="'form-control'"
                      [id]="'reason'"
                      [value]="payload.days_to"
                      [placeholder]="'Days To'"
                      [(data)]="payload.days_to">
                  </app-input>
                </div>

                
              </div>

        </div>
        
    </div>

  </div>
  <div class="modal-footer" *ngIf="templates.length > 0">
    <button type="button" class="btn btn-sm btn-primary" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>

<button *ngIf="type=='create'" (click)="open(modalVC)" class="btn btn-sm btn-primary mb-1">Add Schedule</button>
<a *ngIf="type=='update'" class="btn btn-sm btn-primary" (click)="open(modalVC)"> Edit</a>