import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';


@Injectable({
  providedIn: 'root'
})
export class ClientService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `clients/fetch/client`;
        this.ALL_PATH       = `clients/list/client`;
        this.FIND_PATH      = `clients/find/client`;
        this.UPDATE_PATH    = `clients/update/client`;
        this.CREATE_PATH    = `clients/create/client`;
        this.DELETE_PATH    = `clients/delete/client`;
    }

    paidAndOutstanding(identifier: string){

      return this.appService.get("clients/stat/paid-and-outstanding/"+identifier)
    }

    creditRating(identifier: string){
      return this.appService.get("clients/stat/credit-rating/"+identifier)
    }

    sumPaidAndUnpaid(identifier: string){
      return this.appService.get("clients/stat/paid-and-unpaid/"+identifier)
    }

    sumActivityLogs(identifier: string){
      return this.appService.get("clients/stat/activity-and-logs/"+identifier)
    }

    checkCredit(identifier: string){
      return this.appService.get("credit/fetch/credit-check-request/"+identifier)
    }

    getRecievableStats(){
      return this.appService.get("clients/stats/receivables")
    }

    getRevenueStats(){
      return this.appService.get("clients/stats/revenue")
    }

    eventLogs(identifier: any){
        return this.appService.get("clients/fetch/activity-logs/" + identifier)
    }
}