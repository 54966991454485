import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { Financer, Financing } from 'app/main/finance/finance.data';
import { FinanceService, FinancierService } from 'app/services/finance/finance.service';
import { Invoice } from 'app/services/invoices/invoice.model';
import { agreement } from 'app/services/constants/constants';
import { StateTypes } from '../chase/chase.data';

@Component({
  selector: 'invoice-financer',
  templateUrl: './invoice-financer.component.html',
  styleUrls: ['./invoice-financer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceFinancerComponent implements OnInit {

  // public
  @Input() role: string = ""
  @Input() type: string = ""
  @Input() payload: Financer = new Financer()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private financerService: FinancierService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  ngAfterViewInit(): void {
    
    
  }


  public states: StateTypes[] = [
    {id: 1, name: "Pending", key: "Pending"},
    {id: 2, name: "Approved", key: "Approved"},
    {id: 3, name: "Rejected", key: "Rejected"}
  ]
  public selectedState: StateTypes = new StateTypes()

  updateOrCreate(){

    this.payload._state = this.selectedState.name;

    let request: any

      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.financerService.update(body)
      }else{
          //this is create
          request = this.financerService.create(this.payload)
      }

      request.subscribe(response => {

        // console.log("response::: ", response, response.status)
        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)

    }, error => {
        this.toastr.error("Request failed to complete")
    })
  }

  public description: string = ""
  open(modalVC: any) {

    this.modalService.open(modalVC, {
      centered: true
    });
  }
}
