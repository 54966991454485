import { CustomTemplateService, TemplateService } from './../../../services/reminders/reminders.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';
import { Template } from '../reminder.data';
import { UtilService } from 'app/services/utils.service';


@Component({
  selector: 'reminder-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class ReminderTemplateComponent implements OnInit, OnDestroy {
  // public
  public data: any;
  public contentHeader: object;

  @Input() type: string = "User"

  Monthly = false;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {PricingService} _pricingService
   */
  constructor(
    private modalService: NgbModal,
    private templateService: TemplateService, 
    private customTemplateService: CustomTemplateService,
    private utilService: UtilService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.laodRecords()
    this.loadTemplates()
    
    this.contentHeader = {
      headerTitle: 'Reminders',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Reminders',
            isLink: false,
            link: '/'
          },
          {
            name: 'View',
            isLink: false
          }
        ]
      }
    };
  }

  modalOpenVC(modalVC) {
    this.modalService.open(modalVC, {
      centered: true
    });
  }

  reload($event){
      this.laodRecords()
  }

  public emailTemplates: Template[] = new Array<Template>()
  public smsTemplates: Template[] = new Array<Template>()
  loadTemplates(){
    this.templateService.find({type: this.type}).subscribe(response => {
      let data = this.utilService.response(response, true)
      if(data.status) {
        if(data && data.data){
            this.emailTemplates = data.data.filter(x => {
                return x._type == "Email"
            })
            this.smsTemplates = data.data.filter(x => {
              return x._type == "SMS"
          })
        }
    }
    })
  }

  public emails: Template[] = new Array<Template>()
  public smses: Template[] = new Array<Template>()

    laodRecords(){
        this.customTemplateService.find({type: this.type}).subscribe(response => {
          let data = this.utilService.response(response, true)

          console.log("Checking::::", data)
          if(data.status) {
              if(data && data.data){
                  this.emails = data.data.filter(x => {
                      return x._type == "Email"
                  })
                  this.smses = data.data.filter(x => {
                    return x._type == "SMS"
                })
              }
          }
        })
    }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
