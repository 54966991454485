import { CustomerAccessor } from './../../../../services/customers/customer.accessor';
import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import * as snippet from 'app/main/components/tabs/tabs.snippetcode';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Customer } from 'app/services/customers/customer.model';
import { UtilService } from 'app/services/utils.service';
import { Collection } from 'app/services/modifiers/array.modifier';


@Component({
  selector: 'customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})
export class CustomersTableComponent implements OnInit, OnDestroy {


  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public isAdmin: boolean = true;
  @Input() customers: Customer[];
    
    constructor(
      private customerAccessor: CustomerAccessor,
      private utilsService: UtilService
      ){

    }
  


    ngOnInit(): void {
        
       
    }

    sum(data: Array<any>, column: string){

      let sum:number = 0
      data.forEach(element => {
        sum += parseFloat(element.total_amount);
      });
      return sum.toFixed(2)
    }


    ngOnDestroy(): void {
        
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
    }
}