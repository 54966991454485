import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';
import moment from 'moment';
import { AuthenticationService } from 'app/auth/service';


@Injectable({
  providedIn: 'root'
})
export class ValueProcessor {
    constructor(private authService: AuthenticationService) {
    }

    public data: any;

    // Defines the possible keys for replacement
    keys() {
        return {
            customer_name: this.getCustomerName(),
            recipient_firstname: this.getRecipientFirstName(),
            recipient_surname: this.getRecipientSurname(),
            recipient_fullname: this.getRecipientFullName(),
            sender_firstname: this.getSenderFirstName(),
            sender_surname: this.getSenderSurname(),
            sender_fullname:this.getSenderFullName(),
            customer_payment_portal: this.getCustomerPaymentPortal(),
            due_date: this.getDueDate(),
            invoice_issue_date: this.getInvoiceIssueDate(),
            invoice_reference_number: this.getInvoiceReferenceNumber(),
            invoice_reference_number_with_link: this.getInvoiceReferenceNumberWithLink(),
            invoice_total: this.getInvoiceTotal(),
            invoice_amount_owed: this.getInvoiceAmountOwed(),
            days_overdue: this.getDaysOverdue(),
            weeks_overdue: this.getWeeksOverdue(),
            months_overdue: this.getMonthsOverdue(),
            days_until_due_date: this.getDaysUntilDueDate(),
            weeks_until_due_date: this.getWeeksUntilDueDate(),
            months_until_due_date: this.getMonthsUntilDueDate(),
        };
    }

    // Replaces placeholders in the template with actual values
    map(record, string) {

        this.data = record;

        const replacements = this.keys();

        for (const [key, value] of Object.entries(replacements)) {
            let placeholder = `<span style="background-color: rgb(216, 213, 246);">&lt;${key}&gt;</span>`;
            string = string.replace(placeholder, value);

            placeholder = `&lt;${key}&gt;`;
            string = string.replace(placeholder, value);

            string = string.replace("background-color: rgb(216, 213, 246);", "");
        }

        return string;
    }

    // Extract Customer Name
    getCustomerName() {
        return "John Doe"
    }

    // Extract Recipient First Name
    getRecipientFirstName() {
        return "John"
    }

    // Extract Recipient Surname
    getRecipientSurname() {
        return "Doe"
    }

    // Extract Full Name of Recipient
    getRecipientFullName() {
        return "John Doe Brian"
    }

    // Extract Sender's First Name
    getSenderFirstName() {
        try{
            const names = this.getSenderFullName()
            const n = names.split(' ')
            return `${n[0]}`
        }catch(e){
            return ""
        }
    }

    // Extract Sender's Surname
    getSenderSurname() {
        try{
            const names = this.getComapny()
            return names.name
        }catch(e){
            return ""
        }
    }

    getComapny(){
        let activeUser = this.authService.getActiveUser()
        if(activeUser.user){
            return activeUser.user.company
        }
    }

    getSenderFullName(){
        try{
            const names = this.getComapny()
            return names.name
        }catch(e){
            return ""
        }
    }

    // Extract Customer Payment Portal
    getCustomerPaymentPortal() {
        const names = this.authService.getUser()
        return `${names.email}`
    }

    // Extract Invoice Issue Date
    getInvoiceIssueDate() {
        return "2nd July, 2024"
    }

    // Extract Due Date
    getDueDate() {
        return "13th October, 2024"
    }

    // Extract Invoice Reference Number
    getInvoiceReferenceNumber() {
        return "NIV-0876543"
    }

    // Extract Invoice Reference Number with a Link
    getInvoiceReferenceNumberWithLink() {
        const baseUrl = "https://example.com/invoice/";
        return baseUrl + this.getInvoiceReferenceNumber();
    }

    // Extract Total Amount for Invoice
    getInvoiceTotal() {
        return "£456.02"
    }

    // Extract Amount Owed for the Invoice
    getInvoiceAmountOwed() {
        return "£453.02"
    }

    // Calculate Days Overdue
    getDaysOverdue() {
        return "30 days"
    }

    // Calculate Weeks Overdue
    getWeeksOverdue() {
        return "3 weeks"
    }

    // Calculate Months Overdue
    getMonthsOverdue() {
       return '2 months'
    }

    // Calculate Days Until Due Date
    getDaysUntilDueDate() {
        return "7 days "
    }

    // Calculate Weeks Until Due Date
    getWeeksUntilDueDate() {
        return "4 weeks"
    }

    // Calculate Months Until Due Date
    getMonthsUntilDueDate() {
        return "3 months"
    }

    getInvoice() {
        return "NIV-345987"
    }

    getClient() {
        return "John Doe"
    }

    getCompany() {
        // return this.authService.
    }

    _value(obj, key) {
        return obj && obj[key] ? obj[key] : null;
    }

    _value2(obj, parentKey, childKey) {
        return obj && obj[parentKey] && obj[parentKey][childKey] ? obj[parentKey][childKey] : null;
    }
}
