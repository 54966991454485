<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 *ngIf="payload&&!payload.id" class="modal-title" id="exampleModalCenterTitle">Finance</h5>
    <h5 *ngIf="payload&&payload.id" class="modal-title" id="exampleModalCenterTitle">Update Template</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    

    <div class="row">

      <div class="col-lg-6 mt-2">
          
        <app-select
          [list]="states"
          [type]="'text'"
          [id]="'name'"
          [label]="'State'"
          [value]="selectedState.name"
          [placeholder]="'State'"
          [(data)]="selectedState">
        </app-select>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'text'"
            [label]="'Financer Name'"
            [class]="'form-control mt-1'"
            [id]="'name'"
            [value]="payload.name"
            [placeholder]="'Financer Name'"
            [(data)]="payload.name">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'text'"
            [label]="'Address'"
            [class]="'form-control mt-1'"
            [id]="'address'"
            [value]="payload.address"
            [placeholder]="'Address'"
            [(data)]="payload.address">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'text'"
            [label]="'Country'"
            [class]="'form-control mt-1'"
            [id]="'country'"
            [value]="payload.country"
            [placeholder]="'Country'"
            [(data)]="payload.country">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'text'"
            [label]="'Postcode'"
            [class]="'form-control mt-1'"
            [id]="'postcode'"
            [value]="payload.postcode"
            [placeholder]="'Postcode'"
            [(data)]="payload.postcode">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'text'"
            [label]="'City'"
            [class]="'form-control mt-1'"
            [id]="'city'"
            [value]="payload.city"
            [placeholder]="'City'"
            [(data)]="payload.city">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'phone'"
            [label]="'Phone'"
            [class]="'form-control mt-1'"
            [id]="'phone'"
            [value]="payload.phone"
            [placeholder]="'Phone'"
            [(data)]="payload.phone">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'email'"
            [label]="'Email Address'"
            [class]="'form-control mt-1'"
            [id]="'country'"
            [value]="payload.email"
            [placeholder]="'Email Address'"
            [(data)]="payload.email">
        </app-input>
      </div>
    
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>

<button *ngIf="type=='create'" class="btn btn-sm btn-primary mb-1" (click)="open(modalVC)" rippleEffect>
  Add Financer
</button>

<a *ngIf="type=='update'"  href="javascript:void(0)" (click)="open(modalVC)" ngbDropdownItem>Update</a>
