import { InvoiceChaser } from "app/main/receivables/receivable.data"

export class Invoice {
    
        id!: number
        source_record_id!: number
        company_id!: number
        customer_id!: number
        doc_number!: string
        currency!: string
        transaction_date!: string
        due_date!: string
        total_amount!: number
        _finance!: string
        _chase!: string
        _state!: string
        items!: InvoiceItem[]
        status!: number
        created_at!: string
        updated_at!: string
        client!: Client
        company !: Company
        chaser!: InvoiceChaser
    
}

export class Company {

    id!: number
    uuid!: string
    name!: string
    logo!: string
    address!: string
    timezone!: string
    currency_id!: number
    status!: number
    created_at!: string
    updated_at!: string
}

export class Client {
    id!: number
    uuid!: string
    source_record_id!: number
    company_id!: number
    email!: string
    name!: string
    reg_number!: string
    connect_id!: string
    _state!: string
    status!: number
    created_at!: string
    updated_at!: string
    invoices!: Invoice[]
    billing!: Billing
}

export class Billing {

    uuid!: string
    source_record_id!: number
    invoice_id!: number
    customer_id!: number
    address!: string
    city!: string
    postcode!: string
    country!: string
    lat!: number
    lng!: number
    status!: number
}

export class CreditCheckClient {
    
    id!: string
    country!: string
    regNo!: string
    name!: string
    address!: string
    status!: string
    type!: string
    dateOfLatestAccounts!: string
    dateOfLatestChange!: string
    activityCode!: string
    statusDescription!: string
}


export class InvoiceItem {
    
    id!: number
    uuid!: string
    source_record_id!: number
    customer_id!: number
    invoice_id!: number
    reference!: string
    amount!: number
    unit_price!: number
    quantity!: number
    description!: string
    detail!: string
    _state!: number
    status!: number
    created_at!: string
    updated_at!: string
    
}