import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';


@Injectable({
  providedIn: 'root'
})
export class FinanceService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `finances/fetch/financing`;
        this.ALL_PATH       = `finances/list/financing`;
        this.FIND_PATH      = `finances/find/financing`;
        this.UPDATE_PATH    = `finances/update/financing`;
        this.CREATE_PATH    = `finances/create/financing`;
        this.DELETE_PATH    = `finances/delete/financing`;
    }

    loadStats(params: any){
        return this.appService.post('finances/statistic/financing', params);
    }

}


@Injectable({
  providedIn: 'root'
})
export class FinancierService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `finances/fetch/financer`;
        this.ALL_PATH       = `finances/list/financer`;
        this.FIND_PATH      = `finances/find/financer`;
        this.UPDATE_PATH    = `finances/update/financer`;
        this.CREATE_PATH    = `finances/create/financer`;
        this.DELETE_PATH    = `finances/delete/financer`;
    }

}


@Injectable({
    providedIn: 'root'
  })
  export class FinanceEligibilityService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `finances/fetch/finance-eligibility`;
          this.ALL_PATH       = `finances/list/finance-eligibility`;
          this.FIND_PATH      = `finances/find/finance-eligibility`;
          this.UPDATE_PATH    = `finances/update/finance-eligibility`;
          this.CREATE_PATH    = `finances/create/finance-eligibility`;
          this.DELETE_PATH    = `finances/delete/finance-eligibility`;
      }
    }