import { Invoice } from "app/services/invoices/invoice.model"

export class Dispute {

    id!: number
    uuid!:string
    invoice_id!:number
    company_id!:number
    client_id!:number
    title!:string
    description!:string
    dispute_date!:string
    reason!:string
    status!:number 
    _state!: string                //     $table->enum('status!:string ['open!:string 'closed!:string 'in_progress'])->default('open');
    resolution_date!:string
    comments!:string
    created_at!: string
    updated_at!: string
    invoice!: Invoice
}

export class DisputeAttachment {

    id!: number
    uuid!:string
    company_id!:string
    dispute_id!:string
    file_path!:string
    file_name!:string
    _state!:string
    status!:number
    created_at!: string
    updated_at!: string
}

export class DisputeHistory {

    id!: number
    uuid!:string
    company_id!:number
    dispute_id!:number
    user_id!:number
    _state!:string // ['open!:string 'closed!:string 'in_progress']
    status!:number
    created_at!: string
    updated_at!: string
}

export class DisputeMessage {

    id!: number
    uuid!:string
    company_id!:number
    dispute_id!:number
    user_id!:number
    message!:string
    _state!:number
    status!:number
    created_at!: string
    updated_at!: string
}