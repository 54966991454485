<ng-template #modalVC let-modal>
  <div class="modal-header" *ngIf="schedules.length > 0">
    <h5 *ngIf="payload&&!payload.id" class="modal-title" id="exampleModalCenterTitle">Chaser</h5>
    <!-- <h5 *ngIf="payload&&payload.id" class="modal-title" id="exampleModalCenterTitle">Update Template</h5> -->
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    
        <div class="row">

          <div class="col-lg-12" style="padding:30px" *ngIf="schedules.length == 0">
              <h2>Attach Schedule</h2>
              
          </div>

          <div class="col-lg-12">
                    
                <app-select
                  [list]="options"
                  [type]="'text'"
                  [label]="'Select Schdule Option'"
                  [id]="'name'"
                  [value]="selectedOption.name"
                  [(data)]="selectedOption"
                  (dataChange)="onOptionChanged($event)">
                </app-select>

          </div>
          
            <div class="col-lg-12" *ngIf="showState==1">
                    
                  <app-select
                      [list]="schedules"
                      [type]="'text'"
                      [label]="'Defined Schedule'"
                      [id]="'name'"
                      [value]="selectedSchedule.name"
                      [(data)]="selectedSchedule">
                  </app-select>
      
            </div>

            <div class="col-lg-12 mt-2" *ngIf="showState==2">
              <p>
                  Please, click the link below to navigate to reminders where you will create schedule and attach template.
              </p>
              <a href="javascript:;" (click)="modal.dismiss('Cross click')" routerLink="/reminders/list"> Create My Schedule</a>
            </div>

       </div>
  </div>
  <div class="modal-footer" *ngIf="schedules.length > 0&&showState!=2">
    <button type="button" class="btn btn-sm btn-primary {{ running }}" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> {{ invoice._chase=='Chasing' ? "Stop Now" : "Chase Now" }}
    </button>
  </div>
</ng-template>

<a href="javascript:void(0)" *ngIf="type=='link'" (click)="open(modalVC)" rippleEffect ngbDropdownItem>Edit Schedule</a>

<button *ngIf="type!='link'" class="btn btn-sm btn-primary mr-1 {{ running }}" (click)="open(modalVC)" rippleEffect>
  {{ invoice._chase=='Chasing' ? "Stop Chasing" : "Chase Now" }}
</button>
