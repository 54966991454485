export class Template {

    id: number 
    uuid: string 
    name: string
    subject: string
    body: string
    _action!: string 
    _type!: string
    status: number 
    created_at: string
    updated_at: string
}

export class Reminder {

    id: number 
    uuid: string
    invoice_idid: number 
    company_idid: number 
    reminder_date: string
    reminder_type: string //sms/ email
    method: string
    status: number
    created_at: string
    updated_at: string
}

export class ReminderLog {

    id: number 
    uuid: string
    invoice_idid: number 
    company_idid: number 
    reminder_date: string
    reminder_type: string
    method: string
    statusid: number 
    created_at: string
    updated_at: string
}