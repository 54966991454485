import { Component, ViewChild, AfterViewInit, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class InputSelectComponent {

    @Input() placeholder: string = "";
    @Input() info: string = "";
    @Input() required:boolean = true;
    @Input() name: string = "name";
    @Input() label: string = ""
    @Input() list: Array<any> = new Array<any>()
    @Input() disabled:boolean = false;

    @Input() data: any = {id:0, name:""}
    @Output() dataChange = new EventEmitter<any>();

    constructor(){

    }

    ngOnInit(){


        // console.log("Select:", this.list, this.placeholder)


    }

    ngAfterViewInit(){

    }

    updateSelected($event){

      if(!this.data){
        this.data = {id:null, name:null}
      }
      this.dataChange.emit(this.data)
    }

}
