import { state } from '@angular/animations';
import { ReminderService, TemplateService } from './../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewChild, ViewEncapsulation, EventEmitter, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { StateTypes } from '../../invoices/chase/chase.data';
import Quill from 'quill';
import './span.blot'; 

@Component({
  selector: 'reminder-update-create',
  templateUrl: './update-create.component.html',
  styleUrls: ['./update-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReminderUpdateOrCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('editorContainer', { static: true }) editorContainer!: ElementRef;

  // public
  @Input() type: string = ""
  @Input() action: string = ""
  @Input() payload: Template = new Template()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private templateService: TemplateService,
    private toastr: ToastrService ) {}

    private quill: any;

  /**
   * On init
   */
  ngOnInit() {

  }

  ngAfterViewInit() {

    
  }

  private isEditorActive: boolean = false;

  private initializeQuill() {
    const editorContainer = document.getElementById('editor-container');
    if (editorContainer) {
      this.quill = new (window as any).Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            [{ 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            ['link']
          ]
        }
      });

      // Optionally, set the content of the editor
      this.quill.root.innerHTML = this.payload.body || "";

      // Add event listeners if needed
      this.quill.on('text-change', () => {
        this.payload.body = this.quill.root.innerHTML;
      });

      this.quill.on('editor-change', (eventName: string) => {
        if (eventName === 'selection-change') {
          this.isEditorActive = this.quill.hasFocus();
        }
      });

      this.quill.on('selection-change', (range: any) => {
        if (range) {
          this.storedSelection = range; // Store the current selection
        }
      });
    }
  }

  public storedSelection: any
  insertTextAtCursor(text: string): void {
   
    if (this.quill) {
      const range = this.storedSelection;
      this.quill.insertText(range.index, text, { 'background': "#d8d5f6" });
      this.quill.setSelection(range.index + text.length); // Optionally move cursor after the inserted text
    } else {
      console.error('Quill editor instance is not initialized or no selection stored.');
    }
  }

  @HostListener('window:focus', ['$event'])
  onWindowFocus(event: FocusEvent): void {
    // Update focus status on window focus event
    this.isEditorActive = this.quill && this.quill.hasFocus();
  }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: FocusEvent): void {
    // Update focus status on window blur event
    this.isEditorActive = false;
  }

  
  updateOrCreate(){

    let request: any
      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.templateService.update(body)
      }else{
          //this is create
          this.payload._type  = this.action;
          this.payload._action = "User";
          request = this.templateService.create(this.payload)
      }

      request.subscribe(response => {

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)
    })
  }

  public description: string = ""

  open(modalVC: any) {

    this.modalService.open(modalVC, {size: "lg",
      centered: true
    });

    this.initializeQuill();
  }

  public placeholders:StateTypes[] = //new Array<StateTypes>()
  [
    {id: 1, name: "Customer: First Name", "key":"<firstname>"},
    {id: 2, name: "Receipient: First Name", "key":"<receipient_firstname>"},
    {id: 3, name: "Company: Business Name", "key":"<business_name>"},
    {id: 4, name: "Invoice: Reference", "key":"<invoice_reference>"},
    {id: 5, name: "Invoice: Due Date", "key":"<due_date>"},
    {id: 6, name: "Invoice: Amount", "key":"<amount>"},
    {id: 7, name: "Invoice: Overdue Days", "key":"<days_overdue>"},
    {id: 8, name: "Client: Contact", "key":"<contact_person>"},
    {id: 9, name: "Recipient: Surname", "key":"<surname>"},
    {id: 10, name: "Recipient: Firstname", "key":"<firstname>"},
    {id: 11, name: "Recipient: Full Names", "key":"<fullname>"},
    {id: 12, name: "Sender: Surname", "key":"<sender_surname>"},
    {id: 13, name: "Sender: First Name", "key":"<sender_firstname>"},
    {id: 14, name: "Sender: Full Names", "key":"<sender_fullname>"},
  ]
}


