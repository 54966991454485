import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Dispute } from 'app/main/dispute/dispute.data';
import { FinanceService } from 'app/services/finance/finance.service';
import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';
import { Financing } from '../finance.data';
import { AuthenticationService } from 'app/auth/service';


@Component({
  selector: 'finance-list',
  templateUrl: './finance-list.component.html',
  styleUrls: ['./finance-list.component.scss']
})
export class FinanceListComponent implements OnInit, OnDestroy {

  // public
  public data: any;
  public contentHeader: object;
  @Input() records: Financing[] = new Array<Financing>()
  @Output() onSuccessResceived = new EventEmitter<any>()

  Monthly = false;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {PricingService} _pricingService
   */
  constructor(
    private financeService: FinanceService,
    private toast: ToastrService,
    private authService: AuthenticationService) {
    this._unsubscribeAll = new Subject();
  }


  public role: string = ""
  ngOnInit(): void {
   
  }

  isAdmin(){
    // alert(this.authService.isAdmin)
    return this.authService.isAdmin
  }

  getAmount(data: Dispute){
    return data.invoice.total_amount
  }
  
  getClient(data: Dispute){
      return data.invoice.client.name
  }
  
  getInvoice(data: Dispute){
      return data.invoice.doc_number
  }

  
//   loadDisputes(){
//       this.disputeService.all().subscribe(response => {
//           this.records = this.disputeService.response(response, false)
//       })
//   }

OnFinanceUpdate(item:Financing, state: string){

  const body:any = {}
  body.target = {uuid: item.uuid}
  body.data = {_state: state}
  this.financeService.update(body).subscribe(response => {
      if(response&&response.status == "success"){
          this.toast.success(response.message)
          this.onSuccessResceived.emit(true)
          return
      }
      this.toast.error(response.message)
  })
}


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
