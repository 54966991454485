import { state } from '@angular/animations';
import { ReminderService, TemplateService } from '../../../../services/reminders/reminders.service';
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'app/main/reminders/reminder.data';
import { ToastrService } from 'ngx-toastr';
import { DisputeService } from 'app/services/disputes/dispute.service';
import { Dispute } from 'app/main/dispute/dispute.data';
import { Invoice } from 'app/services/invoices/invoice.model';

@Component({
  selector: 'invoice-dispute',
  templateUrl: './invoice-dispute.component.html',
  styleUrls: ['./invoice-dispute.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceDisputeComponent implements OnInit {

  // public
  @Input() type: string = ""
  @Input() payload: Dispute = new Dispute()
  @Input() invoice: Invoice = new Invoice()
  @Output() onSuccessResceived = new EventEmitter<any>()

  constructor(
    private modalService: NgbModal,
    private disputeService: DisputeService,
    private toastr: ToastrService
    ) {}
  /**
   * On init
   */
  ngOnInit() {

  }

  updateOrCreate(){

    this.payload.invoice_id = this.invoice.id
    this.payload.client_id = this.invoice.client.id

    let request: any
      if(this.payload.id){
          //this is an update
          let body:any = {}
          body.data = this.payload;
          body.target = {uuid: this.payload.uuid}
          request = this.disputeService.update(body)
      }else{
          //this is create
          request = this.disputeService.create(this.payload)
      }

      request.subscribe(response => {

        // console.log("response::: ", response, response.status)

        if(response.status == "success"){
            this.toastr.success(response.message)
            this.modalService.dismissAll()
            this.onSuccessResceived.emit(true)
            return;
        }
        this.toastr.error(response.message)
    })
  }

  public description: string = ""
  open(modalVC: any) {

    this.modalService.open(modalVC, {
      centered: true
    });
  }
}
