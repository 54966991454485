import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';


@Injectable({
  providedIn: 'root'
})
export class CollectorService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `collection/fetch/collector`;
        this.ALL_PATH       = `collection/list/collector`;
        this.FIND_PATH      = `collection/find/collector`;
        this.UPDATE_PATH    = `collection/update/collector`;
        this.CREATE_PATH    = `collection/create/collector`;
        this.DELETE_PATH    = `collection/delete/collector`;
    }

}


@Injectable({
  providedIn: 'root'
})
export class CollectionService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `collection/fetch/collection`;
        this.ALL_PATH       = `collection/list/collection`;
        this.FIND_PATH      = `collection/find/collection`;
        this.UPDATE_PATH    = `collection/update/collection`;
        this.CREATE_PATH    = `collection/create/collection`;
        this.DELETE_PATH    = `collection/delete/collection`;
    }

}
