<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 *ngIf="payload&&!payload.id" class="modal-title" id="exampleModalCenterTitle">Finance</h5>
    <h5 *ngIf="payload&&payload.id" class="modal-title" id="exampleModalCenterTitle">Update Template</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    

    <div class="row">

      <div class="col-lg-6 mt-2">
          
        <app-select
          [list]="financiers"
          [type]="'text'"
          [label]="'Financiers'"
          [id]="'name'"
          [label]="'Financiers'"
          [value]="selectedFinancer.name"
          [placeholder]="'Outlets'"
          [(data)]="selectedFinancer">
        </app-select>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'number'"
            [label]="'Amount'"
            [class]="'form-control mt-1'"
            [id]="'amount'"
            [value]="payload.amount"
            [placeholder]="'Amount'"
            [(data)]="payload.amount"
            disabled="true">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'datetime-local'"
            [label]="'Start Date'"
            [class]="'form-control mt-1'"
            [id]="'start_date'"
            [value]="payload.start_date"
            [placeholder]="'Start Date'"
            [(data)]="payload.start_date">
        </app-input>
      </div>

      <div class="col-lg-6 mt-2">
        <app-input
            [type]="'datetime-local'"
            [label]="'End Date'"
            [class]="'form-control mt-1'"
            [id]="'end_date'"
            [value]="payload.end_date"
            [placeholder]="'End Date'"
            [(data)]="payload.end_date">
        </app-input>
      </div>
      
        <div class="col-lg-12 mt-2">

            <div class="wrap-agreement" [innerHTML]="agreement"></div>

        </div>
        <div class="col-lg-12 mt-2">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="remember-me" tabindex="3" />
              <label class="custom-control-label" for="remember-me"> I agree, please continue </label>
            </div>
          </div>
        </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary {{ running }}" (click)="updateOrCreate()" rippleEffect>
      <i class="fa fa-save"></i> Save Record
    </button>
  </div>
</ng-template>


<button class="btn btn-sm btn-primary mr-1" (click)="open(modalVC)" rippleEffect>
  Finance
</button>
