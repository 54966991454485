import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';


@Injectable({
  providedIn: 'root'
})
export class DisputeService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `dispute/fetch/dispute`;
        this.ALL_PATH       = `dispute/list/dispute`;
        this.FIND_PATH      = `dispute/find/dispute`;
        this.UPDATE_PATH    = `dispute/update/dispute`;
        this.CREATE_PATH    = `dispute/create/dispute`;
        this.DELETE_PATH    = `dispute/delete/dispute`;
    }

}
