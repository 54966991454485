import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';


@Injectable({
  providedIn: 'root'
})
export class ReminderService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `reminders/fetch/reminder`;
        this.ALL_PATH       = `reminders/list/reminder`;
        this.FIND_PATH      = `reminders/find/reminder`;
        this.UPDATE_PATH    = `reminders/update/reminder`;
        this.CREATE_PATH    = `reminders/create/reminder`;
        this.DELETE_PATH    = `reminders/delete/reminder`;
    }

}


@Injectable({
  providedIn: 'root'
})
export class TemplateService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `reminders/fetch/template`;
        this.ALL_PATH       = `reminders/list/templates`;
        this.FIND_PATH      = `reminders/find/templates`;
        this.UPDATE_PATH    = `reminders/update/template`;
        this.CREATE_PATH    = `reminders/create/template`;
        this.DELETE_PATH    = `reminders/delete/template`;
    }

}

@Injectable({
  providedIn: 'root'
})
export class CustomTemplateService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `reminders/fetch/custom-templates`;
        this.ALL_PATH       = `reminders/list/custom-templates`;
        this.FIND_PATH      = `reminders/find/custom-templates`;
        this.UPDATE_PATH    = `reminders/update/custom-templates`;
        this.CREATE_PATH    = `reminders/create/custom-templates`;
        this.DELETE_PATH    = `reminders/delete/custom-templates`;
    }

}

