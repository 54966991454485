import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class RecordStatusComponent implements OnInit {

  @Input() state: string = ""

  constructor() {
  }

  ngOnInit(): void {
    
  }
}
