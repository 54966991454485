import { CustomerService } from './customer.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Customer } from './customer.model';


@Injectable({
  providedIn: 'root'
})
export class CustomerAccessor {

    constructor(private customerService: CustomerService ){}

    // Private
    private _customers: BehaviorSubject<Customer[] | null> = new BehaviorSubject(null);

     /** * Getter for tags             */
     get clients$(): Observable<Customer[]>
     {
         return this._customers.asObservable();
     }

     getCustomers(){
        this.customerService.all().subscribe(response => {
            if(response.status=="success"){ this._customers.next(response.data) }
        }, error => "")
     }
}
