import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { RequestService } from '../request.service';



@Injectable({
  providedIn: 'root'
})
export class CompanyService extends RequestService {

    constructor( public override appService: AppService){
        super(appService)

        this.FETCH_PATH     = `company/fetch/company`;
        this.ALL_PATH       = `company/list/company`;
        this.FIND_PATH      = `company/find/company`;
        this.UPDATE_PATH    = `company/update/company`;
        this.CREATE_PATH    = `company/create/company`;
        this.DELETE_PATH    = `company/delete/company`;
    }

    company(params:any){
        return this.appService.post(`company/create/company`, params)
    }

    update(params:any){
        return this.appService.post(`company/update/company`, params)
    }

    timezone(){
        return this.appService.get(`company/list/timezone`);
    }

    currency(){
        return this.appService.get(`company/list/currency`);
    }

    getConnectTypes(){
        return this.appService.get(`company/list/connect-types`);
    }

    getConnect(type){
        return this.appService.get(`company/fetch/redirect/${type}`)
    }

    setPlan(payload: any){
        return this.appService.post(`company/create/plan`, payload)
    }

    skipConnect(payload: any){
        return this.appService.post(`company/update/skip`, payload)
    }

}


@Injectable({
    providedIn: 'root'
  })
  export class CompanyPaymentService extends RequestService {
  
      constructor( public override appService: AppService){
          super(appService)
  
          this.FETCH_PATH     = `company/fetch/payment`;
          this.ALL_PATH       = `company/list/payment`;
          this.FIND_PATH      = `company/find/payment`;
          this.UPDATE_PATH    = `company/update/payment`;
          this.CREATE_PATH    = `company/create/payment`;
          this.DELETE_PATH    = `company/delete/payment`;
      }
  
  }
  
  