import { Observable, Subject } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry} from 'rxjs/operators';
import { map } from "rxjs/operators"

import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AppService {

  private readonly subjectSource$ = new Subject<object>();

  constructor(public http: HttpClient, private router: Router) { }

  /**
   * 
   * @param fn 
   * @param retryNumb 
   */
  public httpRequestStrategy(fn, retryNumb = 0){
    return fn.pipe(
      retry(retryNumb), 
      catchError(this.handleError.bind(this))
    )
  }

  /**
   * 
   * @param error 
   */
  private handleError(error:HttpErrorResponse){

    let toastMessage = 'Unable to complete request';
    if(error && error.error && error.error.message){
        toastMessage = error.error.message;
    }

    return observableThrowError(error || []);
  }

  /**
   * provide access to observable
   */
  public get getObs(): Observable<object> {
      return this.subjectSource$.asObservable();
  }

 
  //   BASE REQUEST METHODS, GET AND POST

  /**
   * 
   * @param url 
   * @param options 
   * @param headers 
   */
  public get(url, options = {}): Observable<any> {

    // add idempotency key to header
    return this.http.get(`${environment.apiUrl}/api/${url}`, {
        headers: { 'Content-Type': `application/json}`}
    })
  }

  /**
   * 
   * @param url 
   * @param options 
   * @param headers 
   */
  public post(url, options = {}, ): Observable<any> {

    // add idempotency key to header
    return this.http.post(`${environment.apiUrl}/api/${url}`, options, {
        headers: { 'Content-Type': `application/json}`}
    })
  }


  public getRouter(){
    
    return this.router;
  }

}
