import { Invoice } from "app/services/invoices/invoice.model"

export class InvoiceChaser {

    id!:number
    uuid!: string
    company_id!: number
    invoice_id!: number
    schedule_id!: number 
    next_chase_date!: string
    last_chase_date!: string
    _state!: string
    status!: number
    created_at!: string
    updated_at!: string
    invoice!: Invoice
    schedule!: Schedule
}

export class Schedule {

    id!:number
    uuid!: string
    company_id!:number
    template_id!: number
    name!: string
    frequency!: string
    days_from: number = 0
    days_to: number = 0
    end_offset!: number
    _type!: string
    _state!: string
    status!: number
    created_at!: string
    updated_at!: string
}

export class ScheduleCDhaser {
    id!: number
    uuid!: string
    chaser_id!: number
    invoice_id!: number
    customer_id!: number
    chaser_type!: string
    scheduled_datetime!: string
    due_datetime!: string
    priority!: string
    assigned_to!: string
    notes!: string
    completion_datetime!: string
    outcome!: string
    reminder_datetime!: string
    chaser_method!: string
    escalation_level!: string
    recurring!: string
    _state!: number
    status!: number
    created_at!: string
    updated_at!: string
}

export class InvoiceNote {

    id!:number
    uuid!: string
    invoice_id!: number
    company_id!: number
    subject!: string
    message!: string
    _state!: string
    status!: number
    created_at!: string
    updated_at!: string
}