<invoice-financer *ngIf="role=='admin'" [type]="'create'"></invoice-financer>
<div class="table-responsive" style="min-height: 400px;">
    <table class="table">
      <thead>
        <tr>
          <th>SN</th>
          <th>Status</th>
          <th>Name</th>
          <th>Phone</th>
          <th>email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        
          <tr *ngFor="let item of records">
              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">#</span>
                </div>
              </td>
              
              <td>
                  <status [state]="item._state"></status>
              </td>

              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{ item.name }}</span>
                </div>
              </td>
              <td class="text-nowrap">{{ item.phone }}</td>
              <td>{{ item.phone }}</td>
              <td>
                <div ngbDropdown>
                  <a ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    id="dropdownTransactions"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                     <invoice-financer *ngIf="role=='admin'" [payload]="item" [type]="'update'"></invoice-financer>
                    <!-- <a href="javascript:void(0)" *ngIf="role=='admin'" (click)="OnFinanceUpdate(item, 'Approved')" ngbDropdownItem>Approve</a> -->
                    
                  </div>
                </div>
              </td>
            
            </tr>

      </tbody>
    </table>
  </div>