<span *ngIf="state&&state=='Blocked'" class="badge badge-light-danger" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Paid'" class="badge badge-light-success" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Processing'" class="badge badge-light-secondary" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Declined'" class="badge badge-light-danger" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Approved'" class="badge badge-light-success" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Sent'" class="badge badge-light-success" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Pending'" class="badge badge-light-primary" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Rejected'" class="badge badge-light-warning" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Resolved'||state=='Collected'" class="badge badge-light-success" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Cancelled'" class="badge badge-light-danger" text-capitalized="">{{ state }}</span>

<span *ngIf="state&&state=='Trial'" class="badge badge-light-warning" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Regular'" class="badge badge-light-primary" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Basic'" class="badge badge-light-info" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Advanced'" class="badge badge-light-success" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Suspended'" class="badge badge-light-danger" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Cancel'" class="badge badge-light-danger" text-capitalized="">{{ state }}</span>

<span *ngIf="state&&state=='Dispute'" class="badge badge-info-danger" text-capitalized="">{{ state }}</span>

<span *ngIf="state&&state=='Default'" class="badge badge-light-secondary" text-capitalized="">{{ state }}</span>
<span *ngIf="state&&state=='Normal'" class="badge badge-light-info" text-capitalized="">{{ state }}</span>