<div class="table-responsive" style="min-height: 400px;">
    <table class="table">
      <thead>
        <tr>
          <th>SN</th>
          <th>Status</th>
          <th>Clients</th>
          <th>Invoices</th>
          <th>Amount</th>
          <th>Starts</th>
          <th>Ends</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        
          <tr *ngFor="let item of records">
              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">#</span>
                </div>
              </td>

              <td>
                <status [state]="item._state"></status>
              </td>

              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{ getClient(item) }}</span>
                </div>
              </td>
              <td class="text-nowrap">{{ getInvoice(item) }}</td>
              <td>£{{ getAmount(item) }}</td>
              <td>{{ item.start_date }}</td>
              <td>{{ item.end_date }}</td>
              <td>
                <div ngbDropdown>
                  <a ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    id="dropdownTransactions"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                    <a href="javascript:void(0)" *ngIf="isAdmin()" (click)="OnFinanceUpdate(item, 'Approved')" ngbDropdownItem>Approve</a>
                    <a href="javascript:void(0)" *ngIf="isAdmin()" (click)="OnFinanceUpdate(item, 'Declined')" ngbDropdownItem>Declined</a>
                    <a href="javascript:void(0)" *ngIf="isAdmin()" (click)="OnFinanceUpdate(item, 'Processing')" ngbDropdownItem>Processing</a>
                    <a href="javascript:void(0)" *ngIf="isAdmin()" (click)="OnFinanceUpdate(item, 'Paid')" ngbDropdownItem>Paid</a>
                    <a href="javascript:void(0)" (click)="OnFinanceUpdate(item, 'Cancelled')" ngbDropdownItem>Cancel</a>
                  </div>
                </div>
              </td>
            
            </tr>

      </tbody>
    </table>
  </div>